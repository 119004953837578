import React from "react";
import { Box, Typography, TextField, Button } from "@mui/material";
import { makeStyles } from "@mui/styles";

const useStyle = makeStyles({
  subscribeMain: {
    backgroundColor: "#f9f9fa",
    marginTop: "40px",
    marginBottom: "40px",
    paddingTop: "40px",
    paddingBottom: "40px",
  },
  subscribeBox: {
    backgroundColor: "white",
    boxShadow: "0 4px 8px 0 rgba(0,0,0,0.2)",
  },
  subscribeContentBox: {
    textAlign: "center",
    paddingTop: "20px",
    paddingBottom: "20px",
  },
  subscribeInputBox: {
    display: "flex",
    paddingTop: "40px",
    paddingBottom: "40px",
  },
  subscribeInput: {
    borderRadius: "0px",
    width: "100%",
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "lightgray",
      },
      "&:hover fieldset": {
        borderColor: "lightgray",
      },
      "&.Mui-focused fieldset": {
        borderColor: "#4ba0e8",
      },
    },
  },
});
export default function Subscribe(props) {
  const classes = useStyle();
  return (
    <Box className={classes.subscribeMain} >
      <Box className={classes.subscribeBox} sx={{ width: { xs: "90%", sm: "90%", md: "90%", lg: "70%", xl: "70%" }, margin: "auto" }}>
        <Box className={classes.subscribeContentBox}>
          <Typography
            sx={{
              fontFamily: "Poppins",
              fontSize: "24px",
              fontWeight: "600",
              paddingBottom: "20px",
              color: "#4ba0e8",
            }}
          >
            {props.subscribeContent.keepInTouch}
          </Typography>
          <Typography sx={{ fontFamily: "Poppins", width: "95%", margin: "auto" }}>
            {props.subscribeContent.keepInTouchContent}
          </Typography>
          <Box className={classes.subscribeInputBox} sx={{ width: { xs: "90%", sm: "90%", md: "80%", lg: "70%", xl: "70%" }, margin: "auto" }}>
            <TextField
              id="subscribe"
              variant="outlined"
              className={classes.subscribeInput}
              placeholder={`${props.subscribeContent.enterEmailAddress}`}
            />
            <Button
              sx={{
                border: "1px solid #6fbf52",
                backgroundColor: "#6fbf52",
                textTransform: "capitalize",
                padding: "10px 40px",
                fontSize: "14px",
                fontWeight: "600",
                color: "white",
                borderRadius: "0px",
                "&:hover": {
                  backgroundColor: "#6fbf52",
                  color: "#fff",
                  border: "1px solid #6fbf52",
                },
              }}
            >
              {props.subscribeContent.send}
            </Button>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}
