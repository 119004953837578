import React, { useState, useEffect } from "react";
import { Box, Typography, Grid, Button } from "@mui/material";
import axios from "axios";
import { makeStyles } from "@mui/styles";
import { useNavigate } from "react-router-dom";
import DummyImage from "../assets/dummyImage.png";

const useStyle = makeStyles({
  maidSection: {
    marginTop: "30px",
    marginBottom: "40px",
    textAlign: "center",
  },
});
export default function Maids(props) {
  const navigate = useNavigate();
  const [countryStatus, setCountryStatus] = useState("All");
  const [nationality, setNationality] = useState("All");
  const [job, setJob] = useState("All");
  const [experienced, setExperienced] = useState("All");
  const [data, setData] = useState([]);
  const [error, setError] = useState("");
  console.log("error", error);
  const [loadMoreMaids, setLoadMoreMaids] = useState(false);

  useEffect(() => {
    if (countryStatus === "All") {
      axios
        .get(
          "https://alnujoomerp.net/api/v1/Maidsan?api-key=8o0884ws88kkoc484k4s8kg0o04okockk0k0gwso"
        )
        .then((res) => {
          setData(res.data.response);
          document.getElementById("viewAllButtonId").style.display = "block";
        })
        .catch((error) => {
          setError(error.message);
        });
    } else if (countryStatus === "Inside Country") {
      axios
        .get(
          "https://alnujoomerp.net/api/v1/Maidsan?api-key=8o0884ws88kkoc484k4s8kg0o04okockk0k0gwso"
        )
        .then((res) => {
          const inSideCountryStatus = res.data.response;
          const inSideCountryStatusData =
            inSideCountryStatus &&
            inSideCountryStatus.filter(
              (countryStatus) =>
                countryStatus.country_status === "Inside Country"
            );
          setData(inSideCountryStatusData);
          document.getElementById("viewAllButtonId").style.display = "none";
        })
        .catch((error) => {
          setError(error.message);
        });
    } else if (countryStatus === "Out Side Country") {
      axios
        .get(
          "https://alnujoomerp.net/api/v1/Maidsan?api-key=8o0884ws88kkoc484k4s8kg0o04okockk0k0gwso"
        )
        .then((res) => {
          const outSideCountryStatus = res.data.response;
          const outSideCountryStatusData =
            outSideCountryStatus &&
            outSideCountryStatus.filter(
              (countryStatus) =>
                countryStatus.country_status === "Out Side Country"
            );
          setData(outSideCountryStatusData);
          document.getElementById("viewAllButtonId").style.display = "none";
        })
        .catch((error) => {
          setError(error.message);
        });
    }
  }, [countryStatus]);


  useEffect(() => {
    if (experienced === "All") {
      axios
        .get(
          "https://alnujoomerp.net/api/v1/Maidsan?api-key=8o0884ws88kkoc484k4s8kg0o04okockk0k0gwso"
        )
        .then((res) => {
          setData(res.data.response);
          document.getElementById("viewAllButtonId").style.display = "block";
        })
        .catch((error) => {
          setError(error.message);
        });
    } else if (experienced === "Beginner") {
      axios
        .get(
          "https://alnujoomerp.net/api/v1/Maidsan?api-key=8o0884ws88kkoc484k4s8kg0o04okockk0k0gwso"
        )
        .then((res) => {
          const experiencedStatus = res.data.response;
          const experiencedStatusData =
            experiencedStatus &&
            experiencedStatus.filter(
              (experienced) =>
                experienced.experience === "Beginner"
            );
          setData(experiencedStatusData);
          document.getElementById("viewAllButtonId").style.display = "none";
        })
        .catch((error) => {
          setError(error.message);
        });
    } else if (experienced === "Experienced") {
      axios
        .get(
          "https://alnujoomerp.net/api/v1/Maidsan?api-key=8o0884ws88kkoc484k4s8kg0o04okockk0k0gwso"
        )
        .then((res) => {
          const experiencedStatus = res.data.response;
          const experiencedStatusData =
            experiencedStatus &&
            experiencedStatus.filter(
              (experienced) =>
                experienced.experience === "Experienced"
            );
          setData(experiencedStatusData);
          document.getElementById("viewAllButtonId").style.display = "none";
        })
        .catch((error) => {
          setError(error.message);
        });
    }
  }, [experienced]);



  useEffect(() => {
    if (job === "All") {
      axios
        .get(
          "https://alnujoomerp.net/api/v1/Maidsan?api-key=8o0884ws88kkoc484k4s8kg0o04okockk0k0gwso"
        )
        .then((res) => {
          setData(res.data.response);
          document.getElementById("viewAllButtonId").style.display = "block";
        })
        .catch((error) => {
          setError(error.message);
        });
    } else if (job === "House maid") {
      axios
        .get(
          "https://alnujoomerp.net/api/v1/Maidsan?api-key=8o0884ws88kkoc484k4s8kg0o04okockk0k0gwso"
        )
        .then((res) => {
          const jobStatus = res.data.response;
          const jobStatusData =
            jobStatus &&
            jobStatus.filter(
              (jobs) =>
                jobs.job === "House Maid"
            );
          setData(jobStatusData);
          document.getElementById("viewAllButtonId").style.display = "none";
        })
        .catch((error) => {
          setError(error.message);
        });
    } else if (job === "Care giver") {
      axios
        .get(
          "https://alnujoomerp.net/api/v1/Maidsan?api-key=8o0884ws88kkoc484k4s8kg0o04okockk0k0gwso"
        )
        .then((res) => {
          const jobStatus = res.data.response;
          const jobStatusData =
            jobStatus &&
            jobStatus.filter(
              (jobs) =>
                jobs.job === "Care Giver"
            );
          setData(jobStatusData);
          document.getElementById("viewAllButtonId").style.display = "none";
        })
        .catch((error) => {
          setError(error.message);
        });
    }
    else if (job === "Baby sitter") {
      axios
        .get(
          "https://alnujoomerp.net/api/v1/Maidsan?api-key=8o0884ws88kkoc484k4s8kg0o04okockk0k0gwso"
        )
        .then((res) => {
          const jobStatus = res.data.response;
          const jobStatusData =
            jobStatus &&
            jobStatus.filter(
              (jobs) =>
                jobs.job === "Baby Sitter"
            );
          setData(jobStatusData);
          document.getElementById("viewAllButtonId").style.display = "none";
        })
        .catch((error) => {
          setError(error.message);
        });
    }
    else if (job === "Nanny") {
      axios
        .get(
          "https://alnujoomerp.net/api/v1/Maidsan?api-key=8o0884ws88kkoc484k4s8kg0o04okockk0k0gwso"
        )
        .then((res) => {
          const jobStatus = res.data.response;
          const jobStatusData =
            jobStatus &&
            jobStatus.filter(
              (jobs) =>
                jobs.job === "Nanny"
            );
          setData(jobStatusData);
          document.getElementById("viewAllButtonId").style.display = "none";
        })
        .catch((error) => {
          setError(error.message);
        });
    }
    else if (job === "Cook") {
      axios
        .get(
          "https://alnujoomerp.net/api/v1/Maidsan?api-key=8o0884ws88kkoc484k4s8kg0o04okockk0k0gwso"
        )
        .then((res) => {
          const jobStatus = res.data.response;
          const jobStatusData =
            jobStatus &&
            jobStatus.filter(
              (jobs) =>
                jobs.job === "Cook"
            );
          setData(jobStatusData);
          document.getElementById("viewAllButtonId").style.display = "none";
        })
        .catch((error) => {
          setError(error.message);
        });
    }
  }, [job]);


  useEffect(() => {
    if (nationality === "All") {

      axios
        .get(
          "https://alnujoomerp.net/api/v1/Maidsan?api-key=8o0884ws88kkoc484k4s8kg0o04okockk0k0gwso"
        )
        .then((res) => {
          setData(res.data.response);
          document.getElementById("viewAllButtonId").style.display = "block";
        })
        .catch((error) => {
          setError(error.message);
        });
    } else if (nationality === "Indonesia") {
      axios
        .get(
          "https://alnujoomerp.net/api/v1/Maidsan?api-key=8o0884ws88kkoc484k4s8kg0o04okockk0k0gwso"
        )
        .then((res) => {
          const userNationality = res.data.response;
          const nationalityData =
            userNationality &&
            userNationality.filter(
              (nationality) =>
                nationality.nationality === "Indonesia"
            );
          setData(nationalityData);
          document.getElementById("viewAllButtonId").style.display = "none";
        })
        .catch((error) => {
          setError(error.message);
        });
    } else if (nationality === "Uganda") {
      axios
        .get(
          "https://alnujoomerp.net/api/v1/Maidsan?api-key=8o0884ws88kkoc484k4s8kg0o04okockk0k0gwso"
        )
        .then((res) => {
          const userNationality = res.data.response;
          const ugandaNationalityData =
            userNationality &&
            userNationality.filter(
              (nationality) =>
                nationality.nationality === "Uganda"
            );
          setData(ugandaNationalityData);
          document.getElementById("viewAllButtonId").style.display = "none";
        })
        .catch((error) => {
          setError(error.message);
        });
    } else if (nationality === "Ethiopia") {
      axios
        .get(
          "https://alnujoomerp.net/api/v1/Maidsan?api-key=8o0884ws88kkoc484k4s8kg0o04okockk0k0gwso"
        )
        .then((res) => {
          const userNationality = res.data.response;
          const ethiopiaNationalityData =
            userNationality &&
            userNationality.filter(
              (nationality) =>
                nationality.nationality === "Ethiopia"
            );
          setData(ethiopiaNationalityData);
          document.getElementById("viewAllButtonId").style.display = "none";
        })
        .catch((error) => {
          setError(error.message);
        });
    }
    else if (nationality === "Sri lanka") {
      axios
        .get(
          "https://alnujoomerp.net/api/v1/Maidsan?api-key=8o0884ws88kkoc484k4s8kg0o04okockk0k0gwso"
        )
        .then((res) => {
          const userNationality = res.data.response;
          const sriLankaNationalityData =
            userNationality &&
            userNationality.filter(
              (nationality) =>
                nationality.nationality === "Sri Lanka"
            );
          setData(sriLankaNationalityData);
          document.getElementById("viewAllButtonId").style.display = "none";
        })
        .catch((error) => {
          setError(error.message);
        });
    }
    else if (nationality === "Kenya") {
      axios
        .get(
          "https://alnujoomerp.net/api/v1/Maidsan?api-key=8o0884ws88kkoc484k4s8kg0o04okockk0k0gwso"
        )
        .then((res) => {
          const userNationality = res.data.response;
          const kenyaNationalityData =
            userNationality &&
            userNationality.filter(
              (nationality) =>
                nationality.nationality === "Kenya"
            );
          setData(kenyaNationalityData);
          document.getElementById("viewAllButtonId").style.display = "none";
        })
        .catch((error) => {
          setError(error.message);
        });
    }
    else if (nationality === "Philippines") {
      axios
        .get(
          "https://alnujoomerp.net/api/v1/Maidsan?api-key=8o0884ws88kkoc484k4s8kg0o04okockk0k0gwso"
        )
        .then((res) => {
          const userNationality = res.data.response;
          const philippinesNationalityData =
            userNationality &&
            userNationality.filter(
              (nationality) =>
                nationality.nationality === "Philippines"
            );
          setData(philippinesNationalityData);
          document.getElementById("viewAllButtonId").style.display = "none";
        })
        .catch((error) => {
          setError(error.message);
        });
    }
  }, [nationality]);

  const onClickLoadMore = () => {
    setLoadMoreMaids(true);
  };
  const handleViewProfile = (id) => {
    navigate("/profile");
    localStorage.setItem("maidId", id);
  };

  useEffect(() => {
    axios
      .get(
        "https://nujoom.tadbeeralnujoom.ae/api/v1/Maidsan?api-key=8o0884ws88kkoc484k4s8kg0o04okockk0k0gwso"
      )
      .then((res) => {
        setData(res.data.response);
      })
      .catch((error) => {
        setError(error.message);
      });
  }, []);
  const firstTwentyItems = data && data.slice(0, 20);
  const classes = useStyle();
  return (
    <Box className={classes.maidSection}>
      <Typography
        sx={{
          fontFamily: "Poppins",
          fontSize: {
            xs: "24px",
            sm: "24px",
            md: "24px",
            lg: "36px",
            xl: "36px",
          },
          fontWeight: "600",
          color: "#4ba0e8",
        }}
      >
        {props.maidsContent.ourMaidsHeading}
      </Typography>
      <Box sx={{ width: "90%", margin: "auto" }}>
        <Grid container spacing={2}>
          <Grid item xs={6} sm={6} md={6} lg={3} xl={3}>
            <Box >
              <Typography sx={{ textAlign: "left" }}>Country Status</Typography>
              <div className="selectFilter">
                <select
                  name="format"
                  id="format"
                  value={countryStatus}
                  onChange={(e) => {
                    setCountryStatus(e.target.value);
                  }}
                >
                  <option>All</option>
                  <option>Inside Country</option>
                  <option>Out Side Country</option>
                </select>
              </div>
            </Box>
          </Grid>
          <Grid item xs={6} sm={6} md={6} lg={3} xl={3}>
            <Box >
              <Typography sx={{ textAlign: "left" }}>Nationality</Typography>
              <div className="selectFilter">
                <select
                  name="format"
                  id="format"
                  value={nationality}
                  onChange={(e) => {
                    setNationality(e.target.value);
                  }}
                >
                  <option>All</option>
                  <option>Indonesia</option>
                  <option>Uganda</option>
                  <option>Ethiopia</option>
                  <option>Philippines</option>
                  <option>Kenya</option>
                  <option>Sri lanka</option>
                </select>
              </div>
            </Box>
          </Grid>

          <Grid item xs={6} sm={6} md={6} lg={3} xl={3}>
            <Box >
              <Typography sx={{ textAlign: "left" }}>Job</Typography>
              <div className="selectFilter">
                <select
                  name="format"
                  id="format"
                  value={job}
                  onChange={(e) => {
                    setJob(e.target.value);
                  }}
                >
                  <option>All</option>
                  <option>House maid</option>
                  <option>Care giver</option>
                  <option>Cook</option>
                  <option>Nanny</option>
                  <option>Baby sitter</option>
                </select>
              </div>
            </Box>
          </Grid>

          <Grid item xs={6} sm={6} md={6} lg={3} xl={3}>
            <Box >
              <Typography sx={{ textAlign: "left" }}>Experience</Typography>
              <div className="selectFilter">
                <select
                  name="format"
                  id="format"
                  value={experienced}
                  onChange={(e) => {
                    setExperienced(e.target.value);
                  }}
                >
                  <option>All</option>
                  <option>Beginner</option>
                  <option>Experienced</option>
                </select>
              </div>
            </Box>
          </Grid>

        </Grid>
      </Box>

      <Box sx={{ width: "90%", margin: "auto", paddingTop: "60px" }}>
        <Grid container spacing={6}>
          {loadMoreMaids ? (
            <>
              {data &&
                data.map((value) => (
                  <>
                    <Grid item xs={6} sm={6} md={4} lg={3} xl={3}>
                      <Box
                        key={value.id}
                        sx={{
                          backgroundColor: "#fff",
                          boxShadow: "0 2px 4px 0 rgba(0,0,0,0.2)",
                          paddingTop: "20px",
                          paddingBottom: "20px",
                          marginTop: "40px",
                          height: "auto",
                          border: "1px solid #ededed",
                        }}
                      >
                        <img
                          src={value.img ? value.img : DummyImage}
                          alt=""
                          className="maid-image"
                          style={{
                            borderRadius: "100px",
                            marginTop: "-80px",
                          }}
                        />

                        <Typography
                          sx={{
                            fontSize: {
                              xs: "14px",
                              sm: "14px",
                              md: "14px",
                              lg: "18px",
                              xl: "18px",
                            },
                            fontWeight: "500",
                            marginTop: "10px",
                            color: "#4ba0e8",
                          }}
                        >
                          {value.name}
                        </Typography>
                        <Typography
                          sx={{
                            fontSize: {
                              xs: "14px",
                              sm: "14px",
                              md: "14px",
                              lg: "16px",
                              xl: "16px",
                            },
                            fontWeight: "500",
                            color: "rgba(0, 0, 0, 0.6)",
                          }}
                        >
                          {value.nationality}
                        </Typography>
                        <Typography
                          sx={{
                            fontSize: {
                              xs: "14px",
                              sm: "14px",
                              md: "14px",
                              lg: "16px",
                              xl: "16px",
                            },
                            fontWeight: "500",
                            color: "rgba(0, 0, 0, 0.6)",
                            marginBottom: "20px",
                          }}
                        >
                          {value.experience}
                        </Typography>
                        <Box
                          onClick={() => handleViewProfile(value.id)}
                          sx={{
                            border: "1px solid #6fbf52",
                            backgroundColor: "#6fbf52",
                            color: "#fff",
                            width: "52%",
                            margin: "auto",
                            cursor: "pointer",
                            textDecoration: "none",
                            padding: "10px 20px",
                            borderRadius: "100px",
                            fontSize: {
                              xs: "12px",
                              sm: "12px",
                              md: "12px",
                              lg: "16px",
                              xl: "16px",
                            },
                            "&:hover": {
                              backgroundColor: "#fff",
                              color: "#4ba0e8",
                              border: "1px solid #4ba0e8",
                            },
                          }}
                        >
                          {props.maidsContent.viewProfile}
                        </Box>
                      </Box>
                    </Grid>
                  </>
                ))}
            </>
          ) : (
              <>
                {firstTwentyItems &&
                  firstTwentyItems.map((value) => (
                    <>
                      <Grid item xs={6} sm={6} md={4} lg={3} xl={3}>
                        <Box
                          key={value.id}
                          sx={{
                            backgroundColor: "#fff",
                            boxShadow: "0 2px 4px 0 rgba(0,0,0,0.2)",
                            paddingTop: "20px",
                            paddingBottom: "20px",
                            marginTop: "40px",
                            height: "auto",
                            border: "1px solid #ededed",
                          }}
                        >
                          <img
                            src={value.img ? value.img : DummyImage}
                            alt=""
                            className="maid-image"
                            style={{
                              borderRadius: "100px",
                              marginTop: "-80px",
                            }}
                          />

                          <Typography
                            sx={{
                              fontSize: {
                                xs: "14px",
                                sm: "14px",
                                md: "14px",
                                lg: "18px",
                                xl: "18px",
                              },
                              fontWeight: "500",
                              marginTop: "10px",
                              color: "#4ba0e8",
                            }}
                          >
                            {value.name}
                          </Typography>
                          <Typography
                            sx={{
                              fontSize: {
                                xs: "14px",
                                sm: "14px",
                                md: "14px",
                                lg: "16px",
                                xl: "16px",
                              },
                              fontWeight: "500",
                              color: "rgba(0, 0, 0, 0.6)",
                            }}
                          >
                            {value.nationality}
                          </Typography>
                          <Typography
                            sx={{
                              fontSize: {
                                xs: "14px",
                                sm: "14px",
                                md: "14px",
                                lg: "16px",
                                xl: "16px",
                              },
                              marginBottom: "20px",
                              fontWeight: "500",
                              color: "rgba(0, 0, 0, 0.6)",
                            }}
                          >
                            {value.experience}
                          </Typography>
                          <Box
                            onClick={() => handleViewProfile(value.id)}
                            sx={{
                              width: "52%",
                              margin: "auto",
                              border: "1px solid #6fbf52",
                              backgroundColor: "#6fbf52",
                              color: "#fff",
                              cursor: "pointer",
                              textDecoration: "none",
                              padding: "10px 20px",
                              borderRadius: "100px",
                              fontSize: {
                                xs: "12px",
                                sm: "12px",
                                md: "12px",
                                lg: "16px",
                                xl: "16px",
                              },
                              "&:hover": {
                                backgroundColor: "#fff",
                                color: "#4ba0e8",
                                border: "1px solid #4ba0e8",
                              },
                            }}
                          >
                            {props.maidsContent.viewProfile}
                          </Box>
                        </Box>
                      </Grid>
                    </>
                  ))}
              </>
            )}
        </Grid>
        {loadMoreMaids ? (
          ""
        ) : (
            <Box
              sx={{ textAlign: "center", paddingTop: "20px" }}
              id="viewAllButtonId"
            >
              <Button
                sx={{
                  border: "1px solid #6fbf52",
                  backgroundColor: "#6fbf52",
                  textTransform: "capitalize",
                  borderRadius: "100px",
                  padding: "10px 40px",
                  fontSize: "14px",
                  fontWeight: "600",
                  marginTop: "20px",
                  color: "white",
                  "&:hover": {
                    backgroundColor: "#fff",
                    color: "#4ba0e8",
                    border: "1px solid #4ba0e8",
                  },
                }}
                onClick={onClickLoadMore}
              >
                {props.maidsContent.viewAll}
              </Button>
            </Box>
          )}
      </Box>
    </Box>
  );
}
