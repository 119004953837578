import React from "react";
import { Box, Typography, Button } from "@mui/material";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import slide1 from "../assets/slide-1.jpg";
import slide2 from "../assets/slide-2.jpg";
import slide3 from "../assets/slide-3.jpg";

const responsive = {
  superLargeDesktop: {
    breakpoint: { max: 4000, min: 3000 },
    items: 1,
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 1,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 1,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
  },
};

export default function MainCarousel(props) {
  return (
    <>
      <Carousel
        responsive={responsive}
        showDots={true}
        autoPlay={true}
        autoPlaySpeed={2000}
        infinite={true}
      >
        <div style={{ width: "100%", margin: "auto" }}>
          <Box
            sx={{
              backgroundImage: `url(${slide1})`,
              height: {
                xs: "350px",
                sm: "350px",
                md: "600px",
                lg: "628px",
                xl: "628px",
              },
              backgroundRepeat: "no-repeat",
              backgroundSize: "cover",
            }}
          >
            <Box>
              <Typography
                sx={{
                  fontSize: {
                    xs: "14px",
                    sm: "14px",
                    md: "14px",
                    lg: "18px",
                    xl: "18px",
                  },
                  fontWeight: "500",
                  fontFamily: "Poppins",
                  textAlign: "center",
                  color: "white",
                  paddingTop: {
                    xs: "100px",
                    sm: "100px",
                    md: "100px",
                    lg: "140px",
                    xl: "140px",
                  },
                }}
              >
                {props.carouselContent.title}
              </Typography>
              <Typography
                sx={{
                  fontSize: {
                    xs: "24px",
                    sm: "24px",
                    md: "52px",
                    lg: "52px",
                    xl: "52px",
                  },
                  fontWeight: "400",
                  fontFamily: "Poppins",
                  textAlign: "center",
                  color: "white",
                  paddingTop: "20px",
                }}
              >
                {props.carouselContent.description}
              </Typography>
              <Typography
                sx={{
                  fontSize: {
                    xs: "24px",
                    sm: "24px",
                    md: "52px",
                    lg: "52px",
                    xl: "52px",
                  },
                  fontWeight: "400",
                  fontFamily: "Poppins",
                  textAlign: "center",
                  color: "white",
                  marginTop: "-10px",
                }}
              >
                {props.carouselContent.subDescription}
              </Typography>
              <Box sx={{ textAlign: "center", paddingTop: "20px" }}>
                <Button
                  variant="outlined"
                  sx={{
                    border: "1px solid white",
                    color: "white",
                    textTransform: "capitalize",
                    borderRadius: "50px",
                    fontSize: {
                      xs: "14px",
                      sm: "14px",
                      md: "14px",
                      lg: "18px",
                      xl: "18px",
                    },
                    fontWeight: "600",
                    "&:hover": {
                      backgroundColor: "#4ba0e8",
                      color: "#fff",
                      border: "1px solid #4ba0e8",
                    },
                  }}
                >
                  {props.carouselContent.freeEstimate}
                </Button>
              </Box>
            </Box>
          </Box>
        </div>
        <div style={{ width: "100%", margin: "auto" }}>
          <Box
            sx={{
              backgroundImage: `url(${slide2})`,
              height: {
                xs: "350px",
                sm: "350px",
                md: "600px",
                lg: "628px",
                xl: "628px",
              },
              backgroundRepeat: "no-repeat",
              backgroundSize: "cover",
            }}
          >
            <Box>
              <Typography
                sx={{
                  fontSize: {
                    xs: "14px",
                    sm: "14px",
                    md: "14px",
                    lg: "18px",
                    xl: "18px",
                  },
                  fontWeight: "500",
                  fontFamily: "Poppins",
                  textAlign: "center",
                  color: "white",
                  paddingTop: {
                    xs: "100px",
                    sm: "100px",
                    md: "100px",
                    lg: "140px",
                    xl: "140px",
                  },
                }}
              >
                {props.carouselContent.title}
              </Typography>
              <Typography
                sx={{
                  fontSize: {
                    xs: "24px",
                    sm: "24px",
                    md: "52px",
                    lg: "52px",
                    xl: "52px",
                  },
                  fontWeight: "400",
                  fontFamily: "Poppins",
                  textAlign: "center",
                  color: "white",
                  paddingTop: "20px",
                }}
              >
                {props.carouselContent.description}
              </Typography>
              <Typography
                sx={{
                  fontSize: {
                    xs: "24px",
                    sm: "24px",
                    md: "52px",
                    lg: "52px",
                    xl: "52px",
                  },
                  fontWeight: "400",
                  fontFamily: "Poppins",
                  textAlign: "center",
                  color: "white",
                  marginTop: "-10px",
                }}
              >
                {props.carouselContent.subDescription}
              </Typography>
              <Box sx={{ textAlign: "center", paddingTop: "20px" }}>
                <Button
                  variant="outlined"
                  sx={{
                    border: "1px solid white",
                    color: "white",
                    textTransform: "capitalize",
                    borderRadius: "50px",
                    fontSize: {
                      xs: "14px",
                      sm: "14px",
                      md: "14px",
                      lg: "18px",
                      xl: "18px",
                    },
                    fontWeight: "600",
                    "&:hover": {
                      backgroundColor: "#4ba0e8",
                      color: "#fff",
                      border: "1px solid #4ba0e8",
                    },
                  }}
                >
                  {props.carouselContent.freeEstimate}
                </Button>
              </Box>
            </Box>
          </Box>
        </div>
        <div style={{ width: "100%", margin: "auto" }}>
          <Box
            sx={{
              backgroundImage: `url(${slide3})`,
              height: {
                xs: "350px",
                sm: "350px",
                md: "600px",
                lg: "628px",
                xl: "628px",
              },
              backgroundRepeat: "no-repeat",
              backgroundSize: "cover",
            }}
          >
            <Box>
              <Typography
                sx={{
                  fontSize: {
                    xs: "14px",
                    sm: "14px",
                    md: "14px",
                    lg: "18px",
                    xl: "18px",
                  },
                  fontWeight: "500",
                  fontFamily: "Poppins",
                  textAlign: "center",
                  color: "white",
                  paddingTop: {
                    xs: "100px",
                    sm: "100px",
                    md: "100px",
                    lg: "140px",
                    xl: "140px",
                  },
                }}
              >
                {props.carouselContent.title}
              </Typography>
              <Typography
                sx={{
                  fontSize: {
                    xs: "24px",
                    sm: "24px",
                    md: "52px",
                    lg: "52px",
                    xl: "52px",
                  },
                  fontWeight: "400",
                  fontFamily: "Poppins",
                  textAlign: "center",
                  color: "white",
                  paddingTop: "20px",
                }}
              >
                {props.carouselContent.description}
              </Typography>
              <Typography
                sx={{
                  fontSize: {
                    xs: "24px",
                    sm: "24px",
                    md: "52px",
                    lg: "52px",
                    xl: "52px",
                  },
                  fontWeight: "400",
                  fontFamily: "Poppins",
                  textAlign: "center",
                  color: "white",
                  marginTop: "-10px",
                }}
              >
                {props.carouselContent.subDescription}
              </Typography>
              <Box sx={{ textAlign: "center", paddingTop: "20px" }}>
                <Button
                  variant="outlined"
                  sx={{
                    border: "1px solid white",
                    color: "white",
                    textTransform: "capitalize",
                    borderRadius: "50px",
                    fontSize: {
                      xs: "14px",
                      sm: "14px",
                      md: "14px",
                      lg: "18px",
                      xl: "18px",
                    },
                    fontWeight: "600",
                    "&:hover": {
                      backgroundColor: "#4ba0e8",
                      color: "#fff",
                      border: "1px solid #4ba0e8",
                    },
                  }}
                >
                  {props.carouselContent.freeEstimate}
                </Button>
              </Box>
            </Box>
          </Box>
        </div>
      </Carousel>
    </>
  );
}
