import React, { useState, useEffect } from "react";
import { Box, Grid, Link, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import axios from "axios";
import youtube from "../assets/youtube.png";
import facebook from "../assets/facebook.png";
import instagram from "../assets/insta.png";
import CheckIcon from "@mui/icons-material/Check";
import PlaceOutlinedIcon from "@mui/icons-material/PlaceOutlined";
import ScheduleOutlinedIcon from "@mui/icons-material/ScheduleOutlined";
import LocalPhoneOutlinedIcon from "@mui/icons-material/LocalPhoneOutlined";

const useStyle = makeStyles({
  footerMain: {
    backgroundColor: "#fff",
  },
  addressStyle: {
    display: "flex",
    marginTop: "15px",
  },
  socialMediaIcons: {
    color: "#899198",
    marginTop: "20px",
  },
  socialMediaIconBox: {
    width: "50px",
    height: "50px",
    backgroundColor: "#ebebef",
    cursor: "pointer",
    borderRadius: "50%",
    marginRight: "20px",
  },
});
export default function Footer(props) {
  const classes = useStyle();
  const [data, setData] = useState([]);
  const [error, setError] = useState("");
  console.log("error", error);
  useEffect(() => {
    axios
      .get(
        "https://nujoom.tadbeeralnujoom.ae/api/v1/Company?api-key=8o0884ws88kkoc484k4s8kg0o04okockk0k0gwso"
      )
      .then((res) => {
        setData(res.data.data); // Assuming the response data is an array or object you want to set
      })
      .catch((error) => {
        setError(error.message);
      });
  }, []);
  return (
    <Box
      className={classes.footerMain}
      sx={{ paddingBottom: "30px", }}
    >
      <Box sx={{ width: "80%", margin: "auto" }}>
        <Grid container spacing={2}>
          <Grid item xs={6} sm={6} md={4} lg={4} xl={4}>
            <Box>
              <Typography
                sx={{ fontWeight: "600", fontSize: "24px", color: "#4b5b68" }}
              >
                {props.footerContent.quickLinks}
              </Typography>
              <Box sx={{ display: "inline-grid" }}>
                <Link
                  href="/aboutus"
                  sx={{
                    textDecoration: "none",
                    color: "#4b5b68",
                    fontSize: "18px",
                    marginRight: {
                      xs: "7px",
                      sm: "7px",
                      md: "12px",
                      lg: "18px",
                      xl: "18px",
                    },
                    marginTop: "10px",
                  }}
                >
                  {props.footerContent.aboutUs}
                </Link>
                <Link
                  href="/services"
                  sx={{
                    textDecoration: "none",
                    color: "#4b5b68",
                    marginRight: {
                      xs: "7px",
                      sm: "7px",
                      md: "12px",
                      lg: "18px",
                      xl: "18px",
                    },
                    fontSize: "18px",
                    marginTop: "10px",
                  }}
                >
                  {props.footerContent.services}
                </Link>
                <Link
                  href="/ourTeam"
                  sx={{
                    textDecoration: "none",
                    color: "#4b5b68",
                    marginRight: {
                      xs: "7px",
                      sm: "7px",
                      md: "12px",
                      lg: "18px",
                      xl: "18px",
                    },
                    marginTop: "10px",
                    fontSize: "18px",
                  }}
                >
                  {props.footerContent.ourTeam}
                </Link>
                <Link
                  href="/contactus"
                  sx={{
                    textDecoration: "none",
                    color: "#4b5b68",
                    marginRight: {
                      xs: "7px",
                      sm: "7px",
                      md: "12px",
                      lg: "18px",
                      xl: "18px",
                    },
                    fontSize: "18px",
                    marginTop: "10px",
                  }}
                >
                  {props.footerContent.contactUs}
                </Link>

              </Box>

            </Box>
          </Grid>
          <Grid item xs={6} sm={6} md={4} lg={4} xl={4}>
            <Box>
              <Typography
                sx={{ fontWeight: "600", fontSize: "24px", color: "#4b5b68" }}
              >
                {props.footerContent.ourServicesHeading}
              </Typography>
              <Box
                sx={{
                  display: "flex",
                  paddingLeft: "10px",
                  paddingTop: "10px",
                }}
              >
                <CheckIcon sx={{ color: "green" }} />
                <Typography sx={{ paddingLeft: "5px", fontSize: "18px" }}>
                  {props.footerContent.cleaningServices}
                </Typography>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  paddingLeft: "10px",
                  paddingTop: "10px",
                }}
              >
                <CheckIcon sx={{ color: "green" }} />
                <Typography sx={{ paddingLeft: "5px", fontSize: "18px" }}>
                  {props.footerContent.apartmentCleaning}
                </Typography>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  paddingLeft: "10px",
                  paddingTop: "10px",
                }}
              >
                <CheckIcon sx={{ color: "green" }} />
                <Typography sx={{ paddingLeft: "5px", fontSize: "18px" }}>
                  {props.footerContent.houseCleaning}
                </Typography>
              </Box>

              <Box
                sx={{
                  display: "flex",
                  paddingLeft: "10px",
                  paddingTop: "10px",
                }}
              >
                <CheckIcon sx={{ color: "green" }} />
                <Typography sx={{ paddingLeft: "5px", fontSize: "18px" }}>
                  {props.footerContent.move}
                </Typography>
              </Box>

            </Box>

          </Grid>
          <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
            <Box>
              <Typography
                sx={{ fontWeight: "600", fontSize: "24px", color: "#4b5b68" }}
              >
                {props.footerContent.contactInformation}
              </Typography>
              <Box className={classes.addressStyle}>
                <PlaceOutlinedIcon style={{ color: "#6fbf52" }} />
                <Typography
                  sx={{
                    fontSize: {
                      xs: "18px",
                      sm: "18px",
                      md: "18px",
                      lg: "18px",
                      xl: "18px",
                    },
                    marginTop: "-5px",
                    marginLeft: "10px",
                  }}
                >
                  {data && data.address}
                </Typography>
              </Box>
              <Box className={classes.addressStyle}>
                <ScheduleOutlinedIcon style={{ color: "#6fbf52" }} />
                <Typography
                  sx={{
                    fontSize: {
                      xs: "18px",
                      sm: "18px",
                      md: "18px",
                      lg: "18px",
                      xl: "18px",
                    },
                    marginTop: "-2px",
                    marginLeft: "10px",
                  }}
                >
                  {data && data.timing}
                </Typography>
              </Box>
              <Box className={classes.addressStyle}>
                <LocalPhoneOutlinedIcon style={{ color: "#6fbf52" }} />
                <Typography
                  sx={{
                    fontSize: {
                      xs: "18px",
                      sm: "18px",
                      md: "18px",
                      lg: "18px",
                      xl: "18px",
                    },
                    marginTop: "-2px",
                    marginLeft: "10px",
                  }}
                >
                  {data && data.phone}
                </Typography>
              </Box>
              <Box
                className={classes.socialMediaIcons}
                sx={{
                  textAlign: {
                    xs: "center",
                    sm: "center",
                    md: "left",
                    lg: "left",
                    xl: "left",
                  },
                  display: "flex",
                }}
              >
                <Link href={data && data.youtube} >
                  <Box className={classes.socialMediaIconBox}>
                    {" "}
                    <img
                      src={`${youtube}`}
                      alt=""
                      className="footerIcon"

                    />
                  </Box>
                </Link>
                <Link href={data && data.facebook} >
                  <Box className={classes.socialMediaIconBox}>
                    {" "}
                    <img
                      src={`${facebook}`}
                      alt=""
                      className="footerIcon"
                    />
                  </Box>
                </Link>
                <Link href={data && data.instagram} >
                  <Box className={classes.socialMediaIconBox}>
                    {" "}
                    <img
                      src={`${instagram}`}
                      alt=""
                      className="footerIcon"
                    />
                  </Box>
                </Link>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
}
