import { Box } from "@mui/material";
import React, { useEffect, useState } from "react";
import Footer from "../components/Footer";
import FooterNav from "../components/FooterNav";
import ContactUs from "../components/ContactUs/Contactus";
import { useLanguage } from "../components/LanguageContext";
import Header from "../components/Drawer";
import Translation from "../components/Translation/Data.json";

const Contact = () => {
  const { language } = useLanguage();
  const [content, setContent] = useState({});

  useEffect(() => {
    if (language === "English") {
      setContent(Translation.english);
      console.log("english text:", Translation.english.title);
    } else if (language === "Arabic") {
      setContent(Translation.arabic);
    }
  }, [language]);


  return (
    <Box>
      <Header />
      <ContactUs contactUsContent={content} />
      <Footer footerContent={content} />
      <FooterNav footerNavContent={content} />
    </Box>
  );
};
export default Contact;
