import React, { useState, useEffect } from "react";
import { Box } from "@mui/material";
import Translation from "../components/Translation/Data.json";
import Profile from "../components/ViewProfile/profile";
import Header from "../components/Drawer";
import { useLanguage } from "../components/LanguageContext";


const ViewProfile = () => {
  const { language } = useLanguage();
  const [content, setContent] = useState({});
  console.log("content", content);

  useEffect(() => {
    if (language === "English") {
      setContent(Translation.english);
      // console.log("english text:", Translation.english.title);
    } else if (language === "Arabic") {
      setContent(Translation.arabic);
    }
  }, [language]);

  return (
    <Box>
      <Header />
      <Profile />
    </Box>
  );
};
export default ViewProfile;
