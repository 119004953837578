import React from "react";
import { Routes, Route } from "react-router-dom";
import Home from "./pages/Home";
import OurTeam from "./pages/OurTeam";
import Services from "./pages/Services";
import AboutUs from "./pages/AboutUs";
import ContactUs from "./pages/ContactUs";
import ViewProfile from "./pages/ViewProfile";
import Wishlist from "./pages/Wishlist";
import UpcomingInterviews from "./pages/Interviews";

const App = () => {
  return (
    <>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/ourTeam" element={<OurTeam />} />
        <Route path="/services" element={<Services />} />
        <Route path="/aboutUs" element={<AboutUs />} />
        <Route path="/contactUs" element={<ContactUs />} />
        <Route exact path="/profile" element={<ViewProfile />} />
        <Route exact path="/wishlist" element={<Wishlist />} />
        <Route exact path="/upcoming_interviews" element={<UpcomingInterviews />} />
      </Routes>
    </>
  );
};

export default App;
