import React from "react";
import { Box, Link, Grid } from "@mui/material";
import { makeStyles } from "@mui/styles";
import footerLogo from "../assets/footerLogo.png"

const useStyle = makeStyles({
  footerMainNav: {
    paddingTop: "20px",
    width: "90%",
    margin: "auto",
  },
  socialMediaIcons: {
    color: "#899198",
  },
});
export default function FooterNav(props) {
  const classes = useStyle();
  return (
    <Box sx={{ backgroundColor: "#f9f9fa" }}>
      <Box className={classes.footerMainNav}>
        <Grid container spacing={2}>
          <Grid item xs={2} sm={2} md={4} lg={4} xl={4}>
            <Box>
              <img src={`${footerLogo}`} alt="" />
            </Box>
          </Grid>
          <Grid item xs={10} sm={10} md={8} lg={8} xl={8}>
            <Box sx={{ textAlign: "right" }}>
              <Link
                href="/"
                sx={{
                  textDecoration: "none",
                  color: "#4b5b68",
                  marginRight: "20px",
                  position: "relative",
                  top: "5px"
                }}
              >
                {props.footerNavContent.terms}
              </Link>
              <Link
                href="/"
                sx={{
                  textDecoration: "none",
                  color: "#4b5b68",
                  position: "relative",
                  top: "5px"
                }}
              >
                {props.footerNavContent.policy}
              </Link>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
}
