import React from "react";
import { Box, Grid, Typography, Button } from "@mui/material";
import { makeStyles } from "@mui/styles";
import slide1 from "../assets/slide-1.jpg";

const useStyle = makeStyles({
  servicesMain: {
    backgroundColor: "#f9f9fa",
    paddingTop: "20px",
    paddingBottom: "40px",
  },
});
export default function Services(props) {
  const classes = useStyle();
  return (
    <Box>

      <Box className={classes.servicesMain}>
        <Typography
          sx={{
            textAlign: "center",
            color: "#4ba0e8",
            fontSize: { xs: "24px", sm: "24px", md: "24px", lg: "36px", xl: "36px" },
            fontWeight: "600",
            width: { xs: "90%", sm: "90%", md: "90%", lg: "60%", xl: "60%" },
            margin: "auto",
            paddingBottom: "50px"
          }}
        >
          {props.servicesContent.servicesHeading}
        </Typography>

        <Box sx={{ width: "90%", margin: "auto" }}>
          <Grid container spacing={6}>
            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
              <img
                src={`${slide1}`}
                alt=""
                style={{ width: "100%", height: "350px" }}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
              <Typography
                sx={{
                  color: "#4b5b68",
                  fontSize: { xs: "24px", sm: "24px", md: "24px", lg: "36px", xl: "36px" },
                  fontWeight: "600",
                  paddingTop: "20px",
                }}
              >
                {props.servicesContent.serviceOneHeading}
              </Typography>
              <Typography sx={{ paddingTop: "20px", paddingBottom: "20px" }}>
                {props.servicesContent.serviceOneContent}
              </Typography>

              <Box>
                <Button
                  variant="outlined"
                  sx={{
                    border: "1px solid #6fbf52",
                    backgroundColor: "#6fbf52",
                    color: "white",
                    textTransform: "capitalize",
                    borderRadius: "50px",
                    fontSize: "14px",
                    fontWeight: "600",
                    "&:hover": {
                      backgroundColor: "transparent",
                      color: "#4ba0e8",
                      border: "1px solid #4ba0e8",
                    },
                  }}
                >
                  {props.servicesContent.knowMore}
                </Button>
              </Box>
            </Grid>
          </Grid>
          <Grid container spacing={6}>

            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
              <Typography
                sx={{
                  color: "#4b5b68",
                  fontSize: { xs: "24px", sm: "24px", md: "24px", lg: "36px", xl: "36px" },
                  fontWeight: "600",
                  paddingTop: "20px",
                }}
              >
                {props.servicesContent.serviceTwoHeading}
              </Typography>
              <Typography sx={{ paddingTop: "20px", paddingBottom: "20px" }}>
                {props.servicesContent.serviceTwoContent}
              </Typography>

              <Box>
                <Button
                  variant="outlined"
                  sx={{
                    border: "1px solid #6fbf52",
                    backgroundColor: "#6fbf52",
                    color: "white",
                    textTransform: "capitalize",
                    borderRadius: "50px",
                    fontSize: "14px",
                    fontWeight: "600",
                    "&:hover": {
                      backgroundColor: "transparent",
                      color: "#4ba0e8",
                      border: "1px solid #4ba0e8",
                    },
                  }}
                >
                  {props.servicesContent.knowMore}
                </Button>
              </Box>
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
              <img
                src={`${slide1}`}
                alt=""
                style={{ width: "100%", height: "350px" }}
              />
            </Grid>
          </Grid>
        </Box>
      </Box>
    </Box>
  );
}
