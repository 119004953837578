import React from "react";
import { Box, Grid, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import about1 from "../../assets/about1.jpg";
import whyUs from "../../assets/whyUs.jpg";

const useStyle = makeStyles({
  smallDivider: {
    width: "10%",
    height: "2px",
    backgroundColor: "#4ba0e8",
    margin: "auto",
  },
});
export default function AboutUs(props) {
  const classes = useStyle();
  return (
    <Box className={classes.ourServices}>
      <Box sx={{ width: { xs: "90%", sm: "90%", md: "80%", lg: "60%", xl: "60%" }, margin: "auto", paddingTop: "40px" }}>
        <Typography
          sx={{
            fontFamily: "Poppins",
            fontSize: {
              xs: "24px",
              sm: "24px",
              md: "24px",
              lg: "36px",
              xl: "36px",
            },
            fontWeight: "600",
            color: "#4b5b68",
            textAlign: "center",
            paddingBottom: "10px",
          }}
        >
          {props.aboutUsContent.aboutCompany}
        </Typography>
        <Box className={classes.smallDivider}></Box>
        <Typography
          sx={{
            color: "#4b5b68",
            textAlign: "center",
            paddingBottom: "50px",
            paddingTop: "10px",
          }}
        >
          {props.aboutUsContent.aboutCompanySubContent}
        </Typography>
      </Box>
      <Box sx={{ backgroundColor: "#f9f9fa" }}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
            <Box>
              <img
                src={`${about1}`}
                alt=""
                className="about-image"
                style={{ width: "100%", height: "500px" }}
              />
            </Box>
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
            <Box sx={{ width: "93%", margin: "auto" }}>
              <Typography
                sx={{
                  fontFamily: "Poppins",
                  fontSize: {
                    xs: "16px",
                    sm: "16px",
                    md: "16px",
                    lg: "24px",
                    xl: "24px",
                  },
                  fontWeight: "600",
                  color: "#4b5b68",
                  paddingBottom: "10px",
                }}
              >
                {props.aboutUsContent.tadbeerCenter}
              </Typography>
              <Typography
                sx={{
                  color: "#4b5b68",
                  paddingBottom: "50px",
                  paddingTop: "10px",
                }}
              >
                {props.aboutUsContent.tadbeerCenterContentOne}
                <br />
                <p>{props.aboutUsContent.tadbeerCenterContentTwo}</p>

                <p style={{ fontWeight: "bold" }}>{props.aboutUsContent.tadbeerCenterContentThree}</p>

                <Box>
                  <ul>
                    <li>
                      <p>{props.aboutUsContent.tadbeerCenterServiceOne}</p>
                    </li>
                    <li>
                      <p>{props.aboutUsContent.tadbeerCenterServiceTwo}</p>
                    </li>
                  </ul>
                </Box>

                <p>{props.aboutUsContent.tadbeerCenterContentFour}</p>
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </Box>


      <Box sx={{ width: { xs: "90%", sm: "90%", md: "80%", lg: "60%", xl: "60%" }, margin: "auto", paddingTop: "40px" }}>
        <Typography
          sx={{
            fontFamily: "Poppins",
            fontSize: {
              xs: "24px",
              sm: "24px",
              md: "24px",
              lg: "36px",
              xl: "36px",
            },
            fontWeight: "600",
            color: "#4b5b68",
            textAlign: "center",
            paddingBottom: "10px",
          }}
        >
          {props.aboutUsContent.whyTadbeer}
        </Typography>
        <Box className={classes.smallDivider}></Box>
      </Box>
      <Box sx={{ paddingTop: "50px", width: "80%", margin: "auto" }}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
            <img
              src={`${whyUs}`}
              alt=""
              className="why-us"
              style={{ width: "100%" }}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
            <Box sx={{ paddingLeft: "20px", paddingTop: "10px" }}>

              <Typography
                sx={{
                  color: "#4b5b68",
                  paddingBottom: "50px",
                }}
              >
                <p style={{ fontWeight: "bold" }}> {props.aboutUsContent.motto}</p>
                <ol>
                  <li>{props.aboutUsContent.mottoOne}</li>
                  <li>{props.aboutUsContent.mottoTwo}</li>
                  <li>{props.aboutUsContent.mottoThree}</li>
                  <li>{props.aboutUsContent.mottoFour}</li>
                </ol>
                <p>{props.aboutUsContent.whyTadbeerOne}</p>
                <p>{props.aboutUsContent.whyTadbeerTwo}</p>
                <p>{props.aboutUsContent.whyTadbeerThree}</p>


              </Typography>
            </Box>
          </Grid>
        </Grid>
      </Box>
      <Box>

      </Box>
    </Box>
  );
}
