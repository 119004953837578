import React, { useEffect, useState } from "react";
import { Box } from "@mui/material";
import Translation from "../components/Translation/Data.json";
import Carousel from "../components/Carousel";
import Maids from "../components/Maids";
import Services from "../components/services";
import Testimonials from "../components/Testimonials";
import Subscribe from "../components/Subscribe";
import Footer from "../components/Footer";
import FooterNav from "../components/FooterNav";
import Header from "../components/Drawer";
import { useLanguage } from "../components/LanguageContext"; // Import useLanguage

const Home = () => {
  const { language } = useLanguage();
  const [content, setContent] = useState({});

  useEffect(() => {
    if (language === "English") {
      setContent(Translation.english);
      // console.log("english text:", Translation.english.title);
    } else if (language === "Arabic") {
      setContent(Translation.arabic);
    }
  }, [language]);


  return (
    <Box>
      <Header />
      <Carousel carouselContent={content} /> {/* Pass content as a prop */}
      <Maids maidsContent={content} />
      <Services servicesContent={content} />
      <Testimonials testimonialContent={content} />
      <Subscribe subscribeContent={content} />
      <Footer footerContent={content} />
      <FooterNav footerNavContent={content} />
    </Box>
  );
};
export default Home;
