// LanguageContext.js
import React, { createContext, useContext, useState } from "react";

const LanguageContext = createContext();

export const LanguageProvider = ({ children }) => {
  const [language, setLanguage] = useState(localStorage.getItem("selectedLanguage") || "English");
  const [content, setContent] = useState(JSON.parse(localStorage.getItem("contentNew")) || {});
  // console.log("contenttt1",content )

  const updateLanguage = (newLanguage) => {
    setLanguage(newLanguage);
    localStorage.setItem("selectedLanguage", newLanguage);
  };

  const updateContent = (newContent) => {
    setContent(newContent);
    localStorage.setItem("contentNew", JSON.stringify(newContent));
  };

  return (
    <LanguageContext.Provider value={{ language, content, updateLanguage, updateContent }}>
      {children}
    </LanguageContext.Provider>
  );
};

export const useLanguage = () => {
  return useContext(LanguageContext);
};
