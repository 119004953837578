import React, { useState, useEffect } from "react";
import { Box } from "@mui/material";
import Footer from "../components/Footer";
import FooterNav from "../components/FooterNav";
import OurServices from "../components/Services/OurServices";
import Translation from "../components/Translation/Data.json";
import { useLanguage } from "../components/LanguageContext";
import Header from "../components/Drawer";


const Services = () => {
  const { language } = useLanguage();
  const [content, setContent] = useState({});

  useEffect(() => {
    if (language === "English") {
      setContent(Translation.english);
      // console.log("english text:", Translation.english.title);
    } else if (language === "Arabic") {
      setContent(Translation.arabic);
    }
  }, [language]);

  return (
    <Box>
      <Header />
      <OurServices ourServicesContent={content} />
      <Footer footerContent={content} />
      <FooterNav footerNavContent={content} />
    </Box>
  );
};
export default Services;
