import React, { useState, useEffect } from "react";
import { Box, Grid, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import wishlist from "../../assets/wishlist.jpeg";
import { useNavigate } from "react-router-dom";
import DummyImage from "../../assets/dummyImage.png";
import axios from "axios";

const useStyle = makeStyles({
    wishlistMain: {
        backgroundColor: "#fff",
    },
});
export default function UpComingInterviewsMain() {
    const [data, setData] = useState([]);
    const [error, setError] = useState("");
    console.log("error", error);
    const navigate = useNavigate();
    const c_id = localStorage.getItem("c_id");

    useEffect(() => {

        if (c_id !== null) {
            axios
                .get(
                    `https://alnujoomerp.net/api/v1/interview?api-key=8o0884ws88kkoc484k4s8kg0o04okockk0k0gwso&c_id=${c_id}`

                )
                .then((res) => {
                    setData(res.data.data); // Assuming the response data is an array or object you want to set
                })
                .catch((error) => {
                    setError(error.message);
                });
        }
    }, [c_id]);
    const classes = useStyle();
    const handleViewProfile = (id) => {
        navigate("/profile");
        localStorage.setItem("maidId", id);
    };
    return (
        <Box className={classes.wishlistMain}>
            <Box
                style={{
                    backgroundImage: `url(${wishlist})`,
                    backgroundRepeat: "no-repeat",
                    backgroundSize: "cover",
                }}
            >
                <Typography
                    sx={{
                        fontSize: "72px",
                        fontWeight: "bold",
                        color: "white",
                        textAlign: "center",
                        paddingTop: "200px",
                        paddingBottom: "200px",
                    }}
                >
                    Upcoming Interviews
        </Typography>
            </Box>
            <Typography
                sx={{
                    color: "#6fbf52",
                    textAlign: "center",
                    fontSize: "32px",
                    paddingTop: "20px",
                    fontWeight: "600",
                }}
            >
                List of Interview Scheduled
      </Typography>
            <Box sx={{ marginTop: "60px", marginBottom: "40px" }}>
                <Box sx={{ width: "90%", margin: "auto" }}>
                    <Grid container spacing={6}>
                        {data.map((maid, uniId) => (
                            <Grid item xs={6} sm={6} md={4} lg={3} xl={3} key={uniId}>
                                <Box
                                    sx={{
                                        backgroundColor: "#fff",
                                        boxShadow: "0 2px 4px 0 rgba(0,0,0,0.2)",
                                        paddingTop: "20px",
                                        paddingBottom: "20px",
                                        marginTop: "40px",
                                        height: "auto",
                                        border: "1px solid #ededed",
                                        textAlign: "center",
                                    }}
                                >
                                    <img
                                        src={maid.image ? maid.image : DummyImage}
                                        alt=""
                                        className="maid-image"
                                        style={{
                                            borderRadius: "100px",
                                            marginTop: "-80px",
                                        }}
                                    />

                                    <Typography
                                        sx={{
                                            fontSize: {
                                                xs: "14px",
                                                sm: "14px",
                                                md: "14px",
                                                lg: "18px",
                                                xl: "18px",
                                            },
                                            fontWeight: "500",
                                            marginTop: "10px",
                                            color: "#4ba0e8",
                                        }}
                                    >
                                        {maid.maid_name}
                                    </Typography>
                                    <Typography
                                        sx={{
                                            fontSize: {
                                                xs: "14px",
                                                sm: "14px",
                                                md: "14px",
                                                lg: "16px",
                                                xl: "16px",
                                            },
                                            fontWeight: "500",
                                            color: "rgba(0, 0, 0, 0.6)",
                                        }}
                                    >
                                        {maid.nationality}
                                    </Typography>
                                    <Typography
                                        sx={{
                                            fontSize: {
                                                xs: "14px",
                                                sm: "14px",
                                                md: "14px",
                                                lg: "16px",
                                                xl: "16px",
                                            },
                                            fontWeight: "500",
                                            color: "rgba(0, 0, 0, 0.6)",
                                            marginBottom: "20px",
                                        }}
                                    >
                                        {maid.experience}
                                    </Typography>
                                    <Box
                                        onClick={() => handleViewProfile(maid.cv_id)}
                                        sx={{
                                            border: "1px solid red",
                                            backgroundColor: "red",
                                            color: "#fff",
                                            width: "52%",
                                            margin: "auto",
                                            cursor: "pointer",
                                            textDecoration: "none",
                                            padding: "10px 20px",
                                            borderRadius: "100px",
                                            fontSize: {
                                                xs: "12px",
                                                sm: "12px",
                                                md: "12px",
                                                lg: "16px",
                                                xl: "16px",
                                            },
                                            "&:hover": {
                                                backgroundColor: "#fff",
                                                color: "#4ba0e8",
                                                border: "1px solid #4ba0e8",
                                            },
                                        }}
                                    >
                                        History
                                  </Box>
                                </Box>
                            </Grid>
                        ))}
                    </Grid>
                </Box>
            </Box>
        </Box>
    );
}
