import React, { useState, useEffect } from "react";
import { Box } from "@mui/material";
import Translation from "../components/Translation/Data.json";
import Subscribe from "../components/Subscribe";
import Footer from "../components/Footer";
import Header from "../components/Drawer";
import FooterNav from "../components/FooterNav";
import { useLanguage } from "../components/LanguageContext";
import UpComingInterviewsMain from "../components/upcomingInterviews/UpcomingInterviewComp";

const UpcomingInterviews = () => {
    const { language } = useLanguage();
    const [content, setContent] = useState({});

    useEffect(() => {
        if (language === "English") {
            setContent(Translation.english);
            // console.log("english text:", Translation.english.title);
        } else if (language === "Arabic") {
            setContent(Translation.arabic);
        }
    }, [language]);

    return (
        <Box>
            <Header />
            <UpComingInterviewsMain />
            <Subscribe subscribeContent={content} />
            <Footer footerContent={content} />
            <FooterNav footerNavContent={content} />
        </Box>
    );
};
export default UpcomingInterviews;
