import React, { useEffect, useState } from "react";
import {
  Box,
  Grid,
  Typography,
  Button,
  Menu,
  TextField,
  Modal,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import {
  Snackbar,
} from "@mui/material";
import axios from "axios";
import ReactPlayer from "react-player/youtube";
import CircularProgress from "@mui/material/CircularProgress";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { MobileTimePicker } from "@mui/x-date-pickers/MobileTimePicker";
const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  textAlign: "center",
  bgcolor: 'background.paper',
  boxShadow: 24,
  padding: "20px 20px 20px 20px"
};

const useStyle = makeStyles({
  profileMain: {
    padding: "20px 0px",
  },
  viewProfile: {
    width: "80%",
    margin: "auto",
  },
  detailsBox: {
    backgroundColor: "white",
    paddingLeft: "20px",
  },
});
const Profile = () => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [showSuccessMessage, setShowSuccessMessage] = useState(false); // New state for success message
  const [showInterviewSuccessMessage, setShowInterviewSuccessMessage] = useState(false); // New state for success message
  const [openInterviewModal, setOpenInterviewModal] = React.useState(false);
  const handleCloseModalInterview = () => setOpenInterviewModal(false);
  const onClickOkayInterview = () => {
    setOpenInterviewModal(false)
  }
  const handleClickInterview = (event) => {
    if (credentialJWT) {
      setAnchorEl(event.currentTarget);
    }
    else {
      setOpenInterviewModal(true);
    }
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const [selectedTime, setSelectedTime] = useState(null);
  const handleSubmitForm = (e) => {
    e.preventDefault();
    console.log("Form submitted");

    const dateInput = document.querySelector('input[name="date"]');
    const phoneInput = document.querySelector('input[name="phone"]');

    if (dateInput && selectedTime && phoneInput) {
      const interviewDate = dateInput.value;
      const timeDate = new Date(selectedTime);
      const hours = timeDate.getHours();
      const minutes = timeDate.getMinutes();
      const interviewTime = `${hours}:${minutes}`;
      const phone = phoneInput.value;

      const c_id = localStorage.getItem("c_id");
      const c_name = localStorage.getItem("c_name");


      const jsonData = {
        c_id: c_id,
        maid_name: data.name,
        c_name: c_name,
        experience: data.experience,
        nationality: data.nationality,
        image: data.img,
        phone_no: phone,
        application_no: data.application_no,
        interview_date: interviewDate,
        interview_time: interviewTime,
        cv_id: data.id,
      };

      axios({
        method: "post",
        url: `https://alnujoomerp.net/api/v1/interview`,
        data: jsonData,
        headers: {
          "Content-Type": "application/json",
          "api-key": "8o0884ws88kkoc484k4s8kg0o04okockk0k0gwso",
        },
      })
        .then(function (response) {
          setShowInterviewSuccessMessage(true);
          // "interview" API call is successful
          // Now, fetch the "SingleMaid" data
          fetchMaidData(data.id)
            .then(function (maidResponse) {
              const maidData = maidResponse.data.response;
              setData(maidData);
              setExperience(maidData.experience_data);
              setStatus(maidResponse.status);
              setAnchorEl(null);
            })
            .catch(function (maidResponse) {
              setError(maidResponse);
            });
        })
        .catch(function (response) {
          setError(response);
        });

      console.log("Selected Date:", interviewDate);
      console.log("Selected Time:", interviewTime);
      console.log("Phone:", phone);
    }

  };


  const [data, setData] = useState([]);
  const [experience, setExperience] = useState([]);
  const [status, setStatus] = useState("");
  const [error, setError] = useState("");
  const classes = useStyle();
  const maidId = localStorage.getItem("maidId");
  console.log("error", error);

  const fetchMaidData = (maidId) => {
    var bodyFormData = new FormData();
    bodyFormData.append("id", maidId);

    return axios({
      method: "post",
      url: "https://alnujoomerp.net/api/v1/SingleMaid",
      data: bodyFormData,
      headers: {
        "Content-Type": "multipart/form-data",
        "api-key": "8o0884ws88kkoc484k4s8kg0o04okockk0k0gwso",
      },
    });
  };
  function handleCallButtonClick() {
    // Check if data.s_number is defined before using it
    var phoneNumber = data && data.s_number ? data.s_number : "defaultPhoneNumber";

    // Construct the tel link
    var telLink = "tel:" + phoneNumber;

    // Open the phone's dialer with the specified number
    window.location.href = telLink;
  }

  // Add event listener to the button
  var callButton = document.getElementById("callButton");
  if (callButton) {
    callButton.addEventListener("click", handleCallButtonClick);
  }
  function handleWhatsAppButtonClick() {
    // Check if data is defined and has the required properties
    if (data && data.s_number && data.name) {
      var phoneNumber = data.s_number;
      console.log("phone number", phoneNumber);
      var sanitizedPhoneNumber = phoneNumber.replace(/\D/g, '');
      console.log("maidname:", data.name);

      // Check if the phone number is valid (at least 9 digits)
      if (sanitizedPhoneNumber.length >= 9) {
        // Construct the WhatsApp link with maid's name
        var whatsappLink = "https://wa.me/" + sanitizedPhoneNumber + "?text=Hello%20" + encodeURIComponent(data && data.name);

        // Open link in a new tab for valid phone numbers
        window.open(whatsappLink, '_blank');
      }
    } else {
      // Log an error if the required data properties are missing
      console.error("Error: Missing required data properties");
    }
  }




  // Add event listener to the button
  var whatsappButton = document.getElementById("whatsappButton");
  if (whatsappButton) {
    whatsappButton.addEventListener("click", handleWhatsAppButtonClick);
  }


  // Use the useEffect hook to fetch the maid data
  useEffect(() => {
    fetchMaidData(maidId)
      .then(function (response) {
        setData(response.data.response);
        setExperience(response.data.response.experience_data);
        setStatus(response.status);
      })
      .catch(function (response) {
        setError(response);
      });
  }, [maidId]);

  const [open, setOpen] = React.useState(false);
  const handleCloseModal = () => setOpen(false);
  const onClickOkay = () => {
    setOpen(false)
  }
  const credentialJWT = localStorage.getItem("credentialJWT");

  const onClickLater = () => {
    if (credentialJWT) {
      // User is logged in
      const c_id = localStorage.getItem("c_id");
      const c_name = localStorage.getItem("c_name");

      const jsonData = {
        c_id: c_id,
        maid_name: data.name,
        c_name: c_name,
        experience: data.experience,
        nationality: data.nationality,
        image: data.img,
        application_no: data.application_no,
        cv_id: data.id,
      };

      axios({
        method: "post",
        url: `https://nujoom.tadbeeralnujoom.ae/api/v1/Laters`,
        data: jsonData,
        headers: {
          "Content-Type": "application/json",
          "api-key": "8o0884ws88kkoc484k4s8kg0o04okockk0k0gwso",
        }
      })
        .then(function (response) {
          setShowSuccessMessage(true);
          // "Laters" API call is successful
          // Now, fetch the "SingleMaid" data
          fetchMaidData(maidId)
            .then(function (maidResponse) {
              const maidData = maidResponse.data.response;
              setData(maidData);
              setExperience(maidData.experience_data);
              setStatus(maidResponse.status);
            })
            .catch(function (maidResponse) {
              setError(maidResponse);
            });
        })
        .catch(function (response) {
          setError(response);
        });
    } else {
      // User is not logged in
      setOpen(true); // Show the modal
      fetchMaidData(maidId);
    }
  };


  return (
    <Box className={classes.profileMain}>
      <Typography
        sx={{
          fontSize: {
            xs: "24px",
            sm: "24px",
            md: "24px",
            lg: "36px",
            xl: "36px",
          },
          textAlign: "center",
          marginBottom: "30px",
        }}
      >
        Profile Details
      </Typography>
      <Box sx={{ width: "80%", margin: "auto", paddingBottom: "40px" }}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
            <Box sx={{ textAlign: "center" }}>
              <div>
                <Button
                  variant="contained"
                  sx={{
                    backgroundColor: "#6fbf52",
                    width: "80%",
                    color: "white",
                    borderRadius: "100px",
                    fontWeight: "600",
                    textTransform: "capitalize",
                    padding: "10px 0px",
                  }}
                  onClick={handleClickInterview}
                >
                  Request an Interview
                </Button>
                <Menu
                  anchorEl={anchorEl}
                  open={Boolean(anchorEl)}
                  onClose={handleClose}
                >
                  <form onSubmit={handleSubmitForm}>
                    <Box sx={{ padding: "20px" }}>
                      <Typography
                        sx={{
                          fontWeight: "bold",
                          color: "#6fbf52",
                          paddingBottom: "10px",
                        }}
                      >
                        Preferred time for call
                      </Typography>
                      <label htmlFor="date">Date</label>
                      <TextField
                        name="date"
                        type="date"
                        sx={{
                          width: "100%",
                          paddingTop: "10px",
                          paddingBottom: "10px",
                        }}
                      />
                      <label htmlFor="time">Time</label>
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DemoContainer
                          components={[
                            "MobileDateTimePicker",
                            "MobileDateTimePicker",
                          ]}
                        >
                          <MobileTimePicker
                            name="time"
                            openTo="hours"
                            sx={{ marginBottom: "10px" }}
                            value={selectedTime}
                            onChange={(time) => setSelectedTime(time)}
                          />
                        </DemoContainer>
                      </LocalizationProvider>
                      <Typography sx={{ marginTop: "10px" }}>Phone</Typography>
                      <TextField
                        name="phone"
                        type="tel"
                        required
                        sx={{ width: "100%", paddingTop: "10px" }}
                      />
                      <Typography sx={{ fontSize: "12px" }}>
                        We’ll try and match your chosen time, but will be in
                        touch if we need to reschedule.
                      </Typography>
                      <Button
                        type="submit"
                        variant="contained"
                        sx={{ marginTop: "10px" }}
                      >
                        Submit
                      </Button>
                    </Box>
                  </form>
                </Menu>
              </div>
            </Box>
          </Grid>
          <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
            <Modal
              open={openInterviewModal}
              onClose={handleCloseModalInterview}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <Box sx={style}>
                <Typography sx={{ textAlign: "center" }}>
                  User is not logged in to request for an interview! Please login first for making request.
    </Typography>
                <Button variant="contained" sx={{ marginTop: "10px", background: "green" }} onClick={onClickOkayInterview}> Okay</Button>
              </Box>
            </Modal>
            <Modal
              open={open}
              onClose={handleCloseModal}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <Box sx={style}>
                <Typography sx={{ textAlign: "center" }}>
                  User is not logged In to save for laters! Please login first to save it for laters.
    </Typography>
                <Button variant="contained" sx={{ marginTop: "10px", background: "green" }} onClick={onClickOkay}> Okay</Button>
              </Box>
            </Modal>
            <Box sx={{ textAlign: "center" }}>
              <Button
                variant="contained"
                sx={{
                  backgroundColor: "#6fbf52",
                  width: "80%",
                  color: "white",
                  borderRadius: "100px",
                  fontWeight: "600",
                  textTransform: "capitalize",
                  padding: "10px 0px",
                }}
                onClick={onClickLater}
              >
                Save for Later
              </Button>
            </Box>
          </Grid>
          <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
            <Box sx={{ textAlign: "center" }}>
              <Button
                id="callButton"
                variant="contained"
                sx={{
                  backgroundColor: "#6fbf52",
                  width: "80%",
                  color: "white",
                  borderRadius: "100px",
                  fontWeight: "600",
                  textTransform: "capitalize",
                  padding: "10px 0px",
                }}
              >
                Call
</Button>
            </Box>
          </Grid>
          <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>

            <Box sx={{ textAlign: "center" }}>

              <Button
                id="whatsappButton"
                variant="contained"
                sx={{
                  backgroundColor: "#6fbf52",
                  width: "80%",
                  color: "white",
                  borderRadius: "100px",
                  fontWeight: "600",
                  textTransform: "capitalize",
                  padding: "10px 0px",
                }}
              >
                Whatsapp
  </Button>
            </Box>
          </Grid>
        </Grid>
      </Box>
      <Snackbar
        open={showSuccessMessage}
        autoHideDuration={5000} // Adjust the duration as needed
        onClose={() => setShowSuccessMessage(false)}
        message="This maid is added to the favourites!"
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      />
      <Snackbar
        open={showInterviewSuccessMessage}
        autoHideDuration={5000} // Adjust the duration as needed
        onClose={() => setShowInterviewSuccessMessage(false)}
        message="Meeting scheduled for interview!"
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      />
      {status === 200 ? (
        <Box className={classes.viewProfile}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
              {data && data.img && (
                <img
                  src={data.img}
                  alt=""
                  className="maid-image"
                  style={{ width: "100%", height: "auto" }}
                />
              )}

              <Typography
                sx={{
                  fontSize: {
                    xs: "24px",
                    sm: "24px",
                    md: "24px",
                    lg: "24px",
                    xl: "24px",
                  },
                  fontWeight: "600",
                  marginTop: "20px",
                  marginBottom: "20px",
                }}
              >
                {data && data.name && (
                  <>
                    {data.name}
                  </>
                )}
              </Typography>
              <Grid container spacing={2}>
                <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                  <Typography sx={{ marginBottom: "10px", fontWeight: "600" }}>
                    Application No:
                  </Typography>
                </Grid>
                <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                  <Typography sx={{ marginBottom: "10px" }}>
                    {data && data.application_no && (
                      <>
                        {data.application_no}
                      </>
                    )}
                  </Typography>
                </Grid>
                <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                  <Typography sx={{ marginBottom: "10px", fontWeight: "600" }}>
                    Nationality:
                  </Typography>
                </Grid>
                <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                  <Typography sx={{ marginBottom: "10px" }}>
                    {data && data.nationality && (
                      <>
                        {data.nationality}
                      </>
                    )}
                  </Typography>
                </Grid>
                <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                  <Typography sx={{ marginBottom: "10px", fontWeight: "600" }}>
                    Age:
                  </Typography>
                </Grid>
                <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                  <Typography sx={{ marginBottom: "10px" }}>
                    {data && data.age && (
                      <>
                        {data.age} Years
                    </>
                    )}
                  </Typography>
                </Grid>
                <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                  <Typography sx={{ marginBottom: "10px", fontWeight: "600" }}>
                    Date of Birth:
                  </Typography>
                </Grid>
                <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                  <Typography sx={{ marginBottom: "10px" }}>
                    {data && data.dob && (
                      <>
                        {data.dob}
                      </>
                    )}
                  </Typography>
                </Grid>
                <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                  <Typography sx={{ marginBottom: "10px", fontWeight: "600" }}>
                    Number of kids:
                  </Typography>
                </Grid>
                <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                  <Typography sx={{ marginBottom: "10px" }}>
                    {data && data.children ? data.children : "0"}
                  </Typography>
                </Grid>
                <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                  <Typography sx={{ marginBottom: "10px", fontWeight: "600" }}>
                    Marital Status:
                  </Typography>
                </Grid>
                <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                  <Typography sx={{ marginBottom: "10px" }}>
                    {data && data.marital_status && (
                      <>
                        {data.marital_status}
                      </>
                    )}
                  </Typography>
                </Grid>
                <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                  <Typography sx={{ marginBottom: "10px", fontWeight: "600" }}>
                    Religion:
                  </Typography>
                </Grid>
                <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                  <Typography sx={{ marginBottom: "10px" }}>
                    {data && data.religion && (
                      <>
                        {data.religion}
                      </>
                    )}
                  </Typography>
                </Grid>
              </Grid>

            </Grid>
            <Grid item xs={12} sm={12} md={8} lg={8} xl={8}>
              <Box className={classes.detailsBox}>
                <Typography
                  sx={{
                    fontSize: {
                      xs: "16px",
                      sm: "16px",
                      md: "16px",
                      lg: "36px",
                      xl: "36px",
                    },
                    fontWeight: "600",
                    marginBottom: "20px",
                    color: "#6fbf52",
                  }}
                >
                  {data && data.name && (
                    <>
                      {data.name}
                    </>
                  )}
                </Typography>
                <Box>
                  <Typography
                    sx={{
                      fontSize: {
                        xs: "24px",
                        sm: "24px",
                        md: "24px",
                        lg: "24px",
                        xl: "24px",
                      },
                      fontWeight: "600",
                      color: "#4ba0e8",
                    }}
                  >
                    Passport Details
                  </Typography>
                </Box>
                <Box sx={{ marginTop: "20px" }}>
                  <Grid container spacing={2}>
                    <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                      <Typography
                        sx={{ marginBottom: "10px", fontWeight: "600" }}
                      >
                        Passport Number:
                      </Typography>
                    </Grid>
                    <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                      <Typography sx={{ marginBottom: "10px" }}>
                        {data && data.passport_number && (
                          <>
                            {data.passport_number}
                          </>
                        )}
                      </Typography>
                    </Grid>
                    <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                      <Typography
                        sx={{ marginBottom: "10px", fontWeight: "600" }}
                      >
                        Date of issue:
                      </Typography>
                    </Grid>
                    <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                      <Typography sx={{ marginBottom: "10px" }}>
                        {data && data.passport_issue_date && (
                          <>
                            {data.passport_issue_date}
                          </>
                        )}
                      </Typography>
                    </Grid>
                    <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                      <Typography
                        sx={{ marginBottom: "10px", fontWeight: "600" }}
                      >
                        Expiry Date:
                      </Typography>
                    </Grid>
                    <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                      <Typography sx={{ marginBottom: "10px" }}>
                        {data && data.passport_exp_date && (
                          <>
                            {data.passport_exp_date}
                          </>
                        )}
                      </Typography>
                    </Grid>
                    <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                      <Typography
                        sx={{ marginBottom: "10px", fontWeight: "600" }}
                      >
                        Place of issue:
                      </Typography>
                    </Grid>
                    <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                      <Typography sx={{ marginBottom: "10px" }}>
                        {data && data.passport_place_issue && (
                          <>
                            {data.passport_place_issue}
                          </>
                        )}
                      </Typography>
                    </Grid>
                  </Grid>
                </Box>

                <Box className={classes.detailsHeading}>
                  <Typography
                    sx={{
                      fontSize: {
                        xs: "24px",
                        sm: "244px",
                        md: "24px",
                        lg: "24px",
                        xl: "24px",
                      },
                      fontWeight: "600",
                      color: "#4ba0e8",
                    }}
                  >
                    Candidate Details:
                  </Typography>
                </Box>
                <Box sx={{ marginTop: "20px" }}>
                  <Grid container spacing={2}>
                    <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                      <Typography
                        sx={{ marginBottom: "10px", fontWeight: "600" }}
                      >
                        Monthly salary (AED):
                      </Typography>
                    </Grid>
                    <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                      <Typography sx={{ marginBottom: "10px" }}>
                        {data && data.monthly_salary && (
                          <>
                            {data.monthly_salary}
                          </>
                        )}
                      </Typography>
                    </Grid>
                    <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                      <Typography
                        sx={{ marginBottom: "10px", fontWeight: "600" }}
                      >
                        Languages:
                      </Typography>
                    </Grid>
                    <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                      <Typography sx={{ marginBottom: "10px" }}>
                        Arabic: {data && data.lang_arabic} - English:{" "}
                        {data && data.lang_english ? data.lang_english : "0"}
                      </Typography>
                    </Grid>
                    <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                      <Typography
                        sx={{ marginBottom: "10px", fontWeight: "600" }}
                      >
                        Profesion:
                      </Typography>
                    </Grid>
                    <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                      <Typography sx={{ marginBottom: "10px" }}>
                        {data && data.job && (
                          <>
                            {data.job}
                          </>
                        )}
                      </Typography>
                    </Grid>
                    <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                      <Typography
                        sx={{ marginBottom: "10px", fontWeight: "600" }}
                      >
                        Experience:
                      </Typography>
                    </Grid>
                    <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                      <Typography sx={{ marginBottom: "10px" }}>
                        {data && data.experience && (
                          <>
                            {data.experience}
                          </>
                        )}
                      </Typography>
                    </Grid>
                    <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                      <Typography
                        sx={{ marginBottom: "10px", fontWeight: "600" }}
                      >
                        Experience in countries:
                      </Typography>
                    </Grid>
                    <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                      <Typography sx={{ marginBottom: "10px" }}>
                        {experience &&
                          experience.map((value) => (
                            <>
                              <Typography>

                                {value.year} years in {value.country}

                              </Typography>
                            </>
                          ))}
                      </Typography>
                    </Grid>
                  </Grid>
                </Box>
                <Box className={classes.detailsHeading}>
                  <Typography
                    sx={{
                      fontSize: {
                        xs: "24px",
                        sm: "24px",
                        md: "24px",
                        lg: "24px",
                        xl: "24px",
                      },
                      fontWeight: "600",
                      color: "#4ba0e8",
                    }}
                  >
                    Can help with:
                  </Typography>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    marginTop: "10px",
                    marginBottom: "40px",
                  }}
                >
                  <Box sx={{ display: "flex", marginRight: "20px" }}>
                    <Box
                      sx={{
                        width: "7px",
                        height: "7px",
                        borderRadius: "50%",
                        backgroundColor: "black",
                        position: "relative",
                        top: "8px",
                        right: "5px",
                      }}
                    ></Box>
                    <Box>
                      <Typography>Cleaning:
                      {data && data.cleaning && (
                          <>
                            {data.cleaning}
                          </>
                        )}
                      </Typography>
                    </Box>
                  </Box>
                  <Box sx={{ display: "flex", marginRight: "20px" }}>
                    <Box
                      sx={{
                        width: "7px",
                        height: "7px",
                        borderRadius: "50%",
                        backgroundColor: "black",
                        position: "relative",
                        top: "8px",
                        right: "5px",
                      }}
                    ></Box>
                    <Box>
                      <Typography>Cooking:
                      {data && data.cooking && (
                          <>
                            {data.cooking}
                          </>
                        )}
                      </Typography>
                    </Box>
                  </Box>

                  <Box sx={{ display: "flex", marginRight: "20px" }}>
                    <Box
                      sx={{
                        width: "7px",
                        height: "7px",
                        borderRadius: "50%",
                        backgroundColor: "black",
                        position: "relative",
                        top: "8px",
                        right: "5px",
                      }}
                    ></Box>
                    <Box>
                      <Typography>Ironing:
                      {data && data.ironing && (
                          <>
                            {data.ironing}
                          </>
                        )}
                      </Typography>
                    </Box>
                  </Box>

                  <Box sx={{ display: "flex", marginRight: "20px" }}>
                    <Box
                      sx={{
                        width: "7px",
                        height: "7px",
                        borderRadius: "50%",
                        backgroundColor: "black",
                        position: "relative",
                        top: "8px",
                        right: "5px",
                      }}
                    ></Box>
                    <Box>
                      <Typography>Baby Sitting:
                      {data && data.baby_sitting && (
                          <>
                            {data.baby_sitting}
                          </>
                        )}
                      </Typography>
                    </Box>
                  </Box>

                  <Box sx={{ display: "flex", marginRight: "20px" }}>
                    <Box
                      sx={{
                        width: "7px",
                        height: "7px",
                        borderRadius: "50%",
                        backgroundColor: "black",
                        position: "relative",
                        top: "8px",
                        right: "5px",
                      }}
                    ></Box>
                    <Box>
                      <Typography>Washing:
                      {data && data.washing && (
                          <>
                            {data.washing}
                          </>
                        )}
                      </Typography>
                    </Box>
                  </Box>
                </Box>
                <Box>
                  <ReactPlayer
                    width="100%"
                    url={`https://www.youtube.com/watch?v=${data && data.video_link}`}
                  />
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Box>
      ) : (
          <>
            <Box sx={{ textAlign: "center" }}>
              <CircularProgress />
            </Box>
          </>
        )}
    </Box>
  );
};
export default Profile;
