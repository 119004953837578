import React, { useState, useEffect } from "react";
import { Box, Grid, Button, TextField, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import axios from "axios";
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import LocationOnIcon from "@mui/icons-material/LocationOn";
import CallIcon from "@mui/icons-material/Call";
import AlternateEmailIcon from "@mui/icons-material/AlternateEmail";


const useStyle = makeStyles({
  smallDivider: {
    width: "10%",
    height: "2px",
    backgroundColor: "#4ba0e8",
    margin: "auto",
  },
});
export default function ContactUs(props) {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phone: '',
    message: '',
  });
  const [data, setData] = useState([]);
  const [error, setError] = useState("");
  console.log("error", error);
  useEffect(() => {
    axios
      .get(
        "https://nujoom.tadbeeralnujoom.ae/api/v1/Company?api-key=8o0884ws88kkoc484k4s8kg0o04okockk0k0gwso"
      )
      .then((res) => {
        setData(res.data.data); // Assuming the response data is an array or object you want to set
      })
      .catch((error) => {
        setError(error.message);
      });
  }, []);

  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState('');
  const [severity, setSeverity] = useState('success');

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const { name, email, phone, message } = formData;
    const jsonData = {
      name: name,
      email: email,
      phone: phone,
      message: message,
    };

    axios({
      method: 'post',
      url: 'https://nujoom.tadbeeralnujoom.ae/api/v1/contact',
      data: jsonData,
      headers: {
        'Content-Type': 'application/json',
        'api-key': '8o0884ws88kkoc484k4s8kg0o04okockk0k0gwso',
      },
    })
      .then(function (response) {
        setMessage('Your information has been submitted successfully.');
        setSeverity('success');
        setOpen(true);
        setFormData({
          name: '',
          email: '',
          phone: '',
          message: '',
        });
      })
      .catch(function (error) {
        setMessage('An error occurred while submitting the form.');
        setSeverity('error');
        setOpen(true);
      });
  };

  const classes = useStyle();
  return (

    <Box sx={{ paddingBottom: "40px" }}>
      <Box
        sx={{
          width: { xs: "90%", sm: "90%", md: "80%", lg: "60%", xl: "60%" },
          margin: "auto",
          paddingTop: "40px",
        }}
      >
        <Typography
          sx={{
            fontFamily: "Poppins",
            fontSize: {
              xs: "24px",
              sm: "24px",
              md: "24px",
              lg: "36px",
              xl: "36px",
            },
            fontWeight: "600",
            color: "#4b5b68",
            textAlign: "center",
            paddingBottom: "10px",
          }}
        >
          {props.contactUsContent.contactUs}
        </Typography>
        <Box className={classes.smallDivider}></Box>
        <Typography
          sx={{
            color: "#4b5b68",
            textAlign: "center",
            paddingBottom: "50px",
            paddingTop: "10px",
          }}
        >
          {props?.contactUsContent?.contactUsSubContent}
        </Typography>
      </Box>
      <div >
        <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3608.4298893675937!2d55.5174733!3d25.2561203!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3ef58b5e39472d8b%3A0x1cdadc7b355aef1b!2sal%20nujoom%20domestic%20workers!5e0!3m2!1sen!2s!4v1700716494356!5m2!1sen!2s" style={{ border: "0px", width: "100%", height: "500px" }} title="addressMap" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
      </div>

      <Box sx={{ paddingTop: "50px", width: "70%", margin: "auto" }}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12} md={5} lg={5} xl={5}>
            <Typography
              sx={{
                fontFamily: "Poppins",
                fontSize: {
                  xs: "24px",
                  sm: "24px",
                  md: "24px",
                  lg: "36px",
                  xl: "36px",
                },
                fontWeight: "600",
                color: "#4b5b68",
                paddingBottom: "10px",
              }}
            >
              {props?.contactUsContent?.contacts}
            </Typography>
            <Grid container spacing={2} sx={{ marginTop: "10px" }}>
              <Grid item xs={2} sm={2} md={2} lg={1} xl={1}>
                <LocationOnIcon style={{ color: "#61CE70" }} />
              </Grid>
              <Grid item xs={10} sm={10} md={10} lg={11} xl={11}>
                <Typography
                  sx={{
                    fontFamily: "Poppins",
                    fontSize: {
                      xs: "14px",
                      sm: "14px",
                      md: "14px",
                      lg: "18px",
                      xl: "18px",
                    },
                    fontWeight: "600",
                    color: "#4b5b68",
                    paddingBottom: "10px",
                  }}
                >
                  {props?.contactUsContent?.addressHeading}
                </Typography>
                <Typography sx={{ color: "#7A7A7A" }}>
                  {data && data.address}
                </Typography>
              </Grid>
            </Grid>
            <Grid container spacing={2} sx={{ marginTop: "10px" }}>
              <Grid item xs={2} sm={2} md={2} lg={1} xl={1}>
                <CallIcon style={{ color: "#61CE70" }} />
              </Grid>
              <Grid item xs={10} sm={10} md={10} lg={11} xl={11}>
                <Typography
                  sx={{
                    fontFamily: "Poppins",
                    fontSize: {
                      xs: "14px",
                      sm: "14px",
                      md: "14px",
                      lg: "18px",
                      xl: "18px",
                    },
                    fontWeight: "600",
                    color: "#4b5b68",
                    paddingBottom: "10px",
                  }}
                >
                  {props?.contactUsContent?.callHeading}
                </Typography>
                <Typography sx={{ color: "#7A7A7A" }}> {data && data.phone}</Typography>
              </Grid>
            </Grid>
            <Grid container spacing={2} sx={{ marginTop: "10px" }}>
              <Grid item xs={2} sm={2} md={2} lg={1} xl={1}>
                <AlternateEmailIcon style={{ color: "#61CE70" }} />
              </Grid>
              <Grid item xs={10} sm={10} md={10} lg={11} xl={11}>
                <Typography
                  sx={{
                    fontFamily: "Poppins",
                    fontSize: {
                      xs: "14px",
                      sm: "14px",
                      md: "14px",
                      lg: "18px",
                      xl: "18px",
                    },
                    fontWeight: "600",
                    color: "#4b5b68",
                    paddingBottom: "10px",
                  }}
                >
                  {props?.contactUsContent?.emailHeading}
                </Typography>
                <Typography sx={{ color: "#7A7A7A" }}>
                  {data && data.email}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={12} md={7} lg={7} xl={7}>
            <Snackbar
              open={open}
              autoHideDuration={3000}
              onClose={handleClose}
              anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            >
              <MuiAlert
                elevation={6}
                variant="filled"
                onClose={handleClose}
                severity={severity}
              >
                {message}
              </MuiAlert>
            </Snackbar>

            <form onSubmit={handleSubmit}>
              <TextField
                name="name"
                id="outlined-basic"
                placeholder={props?.contactUsContent?.namePlaceholder}
                variant="outlined"
                sx={{ width: "100%" }}
                value={formData.name}
                onChange={handleInputChange}
                required
              />

              <TextField
                name="email"
                id="outlined-basic"
                placeholder={props?.contactUsContent?.emailPlaceholder}
                variant="outlined"
                sx={{ width: "100%", marginTop: "20px" }}
                type="email"
                value={formData.email}
                onChange={handleInputChange}
                required
              />

              <TextField
                name="phone"
                id="outlined-basic"
                placeholder={props?.contactUsContent?.phonePlaceholder}
                variant="outlined"
                sx={{ width: "100%", marginTop: "20px" }}
                type="tel"
                value={formData.phone}
                onChange={handleInputChange}
                required
              />

              <TextField
                name="message"
                id="outlined-multiline-static"
                placeholder={props?.contactUsContent?.messagePlaceholder}
                sx={{ width: "100%", marginTop: "20px" }}
                multiline
                rows={4}
                value={formData.message}
                onChange={handleInputChange}
                required
              />

              <Button
                type="submit"
                sx={{
                  border: "1px solid #4ba0e8",
                  backgroundColor: "#4ba0e8",
                  color: "#fff",
                  textTransform: "capitalize",
                  borderRadius: "100px",
                  padding: "5px 20px",
                  fontSize: {
                    xs: "12px",
                    sm: "12px",
                    md: "12px",
                    lg: "16px",
                    xl: "16px",
                  },
                  fontWeight: "600",
                  marginTop: "20px",
                  "&:hover": {
                    backgroundColor: "#fff",
                    color: "#4ba0e8",
                    border: "1px solid #4ba0e8",
                  },
                }}
              >
                {props?.contactUsContent?.sendMessage}
              </Button>
            </form>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
}
