import React, { useState, useEffect } from "react";
import { Box, Grid, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import axios from "axios";

const useStyle = makeStyles({
  footerMain: {
    backgroundColor: "#f9f9fa",
  },
  smallDivider: {
    width: "10%",
    height: "2px",
    backgroundColor: "#4ba0e8",
    margin: "auto",
  },
});
export default function OurTeamMembers(props) {
  const [data, setData] = useState([]);
  const [error, setError] = useState("");
  console.log("error", error);
  useEffect(() => {
    axios
      .get(
        "https://nujoom.tadbeeralnujoom.ae/api/v1/teams?api-key=8o0884ws88kkoc484k4s8kg0o04okockk0k0gwso"
      )
      .then((res) => {
        setData(res.data.data); // Assuming the response data is an array or object you want to set
      })
      .catch((error) => {
        setError(error.message);
      });
  }, []);

  const classes = useStyle();
  return (
    <Box className={classes.OurTeamMembers} sx={{ paddingBottom: "30px" }}>
      <Box
        sx={{
          width: { xs: "90%", sm: "90%", md: "80%", lg: "60%", xl: "60%" },
          margin: "auto",
          paddingTop: "40px",
        }}
      >
        <Typography
          sx={{
            fontFamily: "Poppins",
            fontSize: {
              xs: "24px",
              sm: "24px",
              md: "24px",
              lg: "36px",
              xl: "36px",
            },
            fontWeight: "600",
            color: "#4b5b68",
            textAlign: "center",
            paddingBottom: "10px",
          }}
        >
          {props.ourTeamContent.ourTeamHeading}
        </Typography>
        <Box className={classes.smallDivider}></Box>
        <Typography
          sx={{
            color: "#4b5b68",
            textAlign: "center",
            paddingBottom: "50px",
            paddingTop: "10px",
          }}
        >
          {props.ourTeamContent.ourTeamSubHeading}
        </Typography>
      </Box>
      <Box sx={{ width: "90%", margin: "auto" }}>
        <Grid container spacing={2}>
          {data.map((teamMember, uniId) => (
            <Grid item xs={6} sm={6} md={4} lg={3} xl={3} key={uniId}>
              <Box
                sx={{
                  textAlign: "center",
                  width: "80%",
                  margin: "auto",
                  padding: "20px",
                  backgroundColor: "#fff",
                  boxShadow: "0 2px 4px 0 rgba(0,0,0,0.2)",
                }}
              >
                <img
                  src={`https://nujoom.tadbeeralnujoom.ae/assets/uploads/${teamMember.img}`}
                  alt=""
                  className="team-member-image"
                />
                <Typography
                  sx={{
                    fontSize: "18px",
                    fontWeight: "600",
                    color: "#4b5b68",
                    paddingTop: "10px",
                  }}
                >
                  {teamMember.name}
                </Typography>
                <Typography
                  sx={{ fontSize: "16px", fontWeight: "400", color: "#4b5b68" }}
                >
                  {teamMember.position}
                </Typography>
                <Typography
                  sx={{
                    fontSize: "16px",
                    fontWeight: "400",
                    color: "#4b5b68",
                    paddingBottom: "10px",
                  }}
                >
                  {teamMember.phone_no}
                </Typography>
              </Box>
            </Grid>
          ))}
        </Grid>
      </Box>
    </Box>
  );
}
