import React, { useState, useEffect } from "react";
import AppBar from "@mui/material/AppBar";
import { Box, ListItemIcon } from "@mui/material";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Menu from "@mui/material/Menu";
import MenuIcon from "@mui/icons-material/Menu";
import Container from "@mui/material/Container";
import MenuItem from "@mui/material/MenuItem";
import Logout from "@mui/icons-material/Logout";
import jwt_decode from "jwt-decode";
import logo from "../assets/logo.png";
import drawerLogo from "../assets/footerLogo.png";
import Translation from "./Translation/Data.json";
import { useNavigate, useLocation } from "react-router-dom";
import { useLanguage } from "./LanguageContext";
import axios from "axios";

function MainNav() {
  const [data, setData] = useState([]);
  const [error, setError] = useState("");
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  console.log("data", data);
  console.log("error", error);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  let location = useLocation();
  const navigate = useNavigate();
  const [anchorElNav, setAnchorElNav] = useState(null);
  const { language, updateLanguage } = useLanguage();

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };
  const handleHomeMenu = () => {
    navigate("/");
  };
  const handleUpcomingInterviews = () => {
    navigate("/upcoming_interviews");
  };
  const handleWishlist = () => {
    navigate("/wishlist");
  };
  const handleOurTeamMenu = () => {
    navigate("/ourTeam");
  };
  const handleServicesMenu = () => {
    navigate("/services");
  };
  const handleAboutUsMenu = () => {
    navigate("/aboutus");
  };
  const handleContactUsMenu = () => {
    navigate("/contactus");
  };

  const [content, setContent] = useState({});
  const [user, setUser] = useState({});
  console.log("userImage", user.picture)
  const credentialJWT = localStorage.getItem("credentialJWT");
  console.log("user", user)
  localStorage.setItem("c_id", user.sub);
  localStorage.setItem("c_name", user.name);
  localStorage.setItem("c_img", user.picture);
  localStorage.setItem("c_email", user.email);

  useEffect(() => {
    if (language === "English") {
      const englishContent = Translation.english;
      setContent(englishContent);
      localStorage.setItem("contentNew", JSON.stringify(englishContent));
    } else if (language === "Arabic") {
      const arabicContent = Translation.arabic;
      setContent(arabicContent);
      localStorage.setItem("contentNew", JSON.stringify(arabicContent));
    }

    // Store the selected language in localStorage
    localStorage.setItem("selectedLanguage", language);


  }, [language]);

  useEffect(() => {
    // If the user data is available, set it here
    const userData = JSON.parse(localStorage.getItem("userData"));
    if (userData) {
      setUser(userData);
    }
  }, []);

  function handleCallbackResponse(response) {
    localStorage.setItem("credentialJWT", response.credential);

    var userObject = jwt_decode(response.credential);

    var jsonData = {
      customer_id: userObject.sub,
      name: userObject.name,
      email: userObject.email,
      image: userObject.picture,
    };

    axios({
      method: "post",
      url: "https://nujoom.tadbeeralnujoom.ae/api/v1/customer",
      data: jsonData,
      headers: {
        "Content-Type": "application/json",
        "api-key": "8o0884ws88kkoc484k4s8kg0o04okockk0k0gwso",
      },
    })
      .then(function (response) {
        setData(response.data.response);
      })
      .catch(function (response) {
        setError(response);
      });

    setUser(userObject);
    localStorage.setItem("userData", JSON.stringify(userObject));
    document.getElementById("signInDiv").hidden = true;
  }

  function handleSignOut(event) {
    localStorage.removeItem("credentialJWT");
    setUser({});
    document.getElementById("signInDiv").hidden = false;
    navigate("/");
  }

  window.onload = function () {
    initializeGoogleSignIn();
  };
  function initializeGoogleSignIn() {
    const google = window.google;

    if (credentialJWT) {
      var signInDiv = document.getElementById("signInDiv");
      if (signInDiv) {
        signInDiv.hidden = true;
      }
    } else {
      if (typeof google !== 'undefined' && typeof google.accounts !== 'undefined' && typeof google.accounts.id !== 'undefined') {
        google.accounts.id.initialize({
          client_id: "431710071344-2k5b652ao0t6j5p8u6u041nipck9ggpe.apps.googleusercontent.com",
          callback: handleCallbackResponse,
        });

        google.accounts.id.renderButton(document.getElementById("signInDiv"), {
          theme: "outline",
          size: "large",
          text: "Sign In", // Set your custom text here
        });
      } else {
        console.error("Google Sign-In library not loaded or available.");
      }
    }
  }
  // Call the initialization function
  initializeGoogleSignIn();
  useEffect(() => {
    if (credentialJWT) {
      const userData = JSON.parse(localStorage.getItem("userData"));
      if (userData) {
        setUser(userData);
      }
    }
  }, [credentialJWT]);

  const handleLanguageChange = (e) => {
    const newLanguage = e.target.value;
    updateLanguage(newLanguage);
  };

  return (
    <Box>
      <AppBar position="static" sx={{ backgroundColor: "white" }}>
        <Container maxWidth="xl">
          <Toolbar disableGutters>
            <Box sx={{ display: { xs: "none", md: "flex" } }}>
              <img src={logo} alt="" style={{ width: "300px", height: "auto" }} />
            </Box>
            <Box sx={{ flexGrow: 1, display: { xs: "flex", md: "none" } }}>

              <IconButton
                size="large"
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={handleOpenNavMenu}
                color="black"
              >
                <MenuIcon />
              </IconButton>
              <Menu
                id="menu-appbar"
                anchorEl={anchorElNav}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "left",
                }}
                keepMounted
                transformOrigin={{
                  vertical: "top",
                  horizontal: "left",
                }}
                open={Boolean(anchorElNav)}
                onClose={handleCloseNavMenu}
                sx={{
                  display: { xs: "block", md: "none" },
                }}
              >
                <MenuItem key="1" onClick={handleHomeMenu}>
                  <Typography textAlign="center">{content.home}</Typography>
                </MenuItem>
                <MenuItem key="2" onClick={handleAboutUsMenu}>
                  <Typography textAlign="center">{content.aboutUs}</Typography>
                </MenuItem>
                <MenuItem key="3" onClick={handleOurTeamMenu}>
                  <Typography textAlign="center">{content.ourTeam}</Typography>
                </MenuItem>
                <MenuItem key="4" onClick={handleServicesMenu}>
                  <Typography textAlign="center">{content.services}</Typography>
                </MenuItem>
                <MenuItem key="5" onClick={handleContactUsMenu}>
                  <Typography textAlign="center">
                    {content.contactUs}
                  </Typography>
                </MenuItem>
              </Menu>

            </Box>

            <Box sx={{ display: { xs: "flex", md: "none" } }}>
              <img src={drawerLogo} alt="" className="drawer-logo" />
              <div className="select">
                <select
                  name="format"
                  id="format"
                  value={language}
                  onChange={handleLanguageChange}
                >
                  <option>English</option>
                  <option>Arabic</option>
                </select>
              </div>

            </Box>
            <Box
              sx={{
                flexGrow: 1,
                display: { xs: "none", md: "flex" },
                justifyContent: "right",
              }}
            >
              <Box
                key="1"
                onClick={handleHomeMenu}
                sx={{
                  my: 3,
                  display: "block",
                  fontWeight: "600",
                  marginRight: "15px",
                  cursor: "pointer",
                  fontSize: "15px",
                }}
                color={location.pathname === "/" ? "green" : "#425d74"}
              >
                {content.home}
              </Box>
              <Box
                key="2"
                onClick={handleAboutUsMenu}
                sx={{
                  my: 3,
                  display: "block",
                  fontWeight: "600",
                  marginRight: "15px",
                  cursor: "pointer",
                  fontSize: "15px",
                }}
                color={location.pathname === "/aboutus" ? "green" : "#425d74"}
              >
                {content.aboutUs}
              </Box>
              <Box
                key="3"
                onClick={handleOurTeamMenu}
                sx={{
                  my: 3,
                  display: "block",
                  fontWeight: "600",
                  marginRight: "15px",
                  cursor: "pointer",
                  fontSize: "15px",
                }}
                color={location.pathname === "/ourTeam" ? "green" : "#425d74"}
              >
                {content.ourTeam}
              </Box>
              <Box
                key="4"
                onClick={handleServicesMenu}
                sx={{
                  my: 3,
                  display: "block",
                  fontWeight: "600",
                  marginRight: "15px",
                  cursor: "pointer",
                  fontSize: "15px",
                }}
                color={location.pathname === "/services" ? "green" : "#425d74"}
              >
                {content.services}
              </Box>

              <Box
                key="5"
                onClick={handleContactUsMenu}
                sx={{
                  my: 3,
                  display: "block",
                  fontWeight: "600",
                  marginRight: "35px",
                  cursor: "pointer",
                  fontSize: "15px",
                }}
                color={location.pathname === "/contactus" ? "green" : "#425d74"}
              >
                {content.contactUs}
              </Box>
              <Box></Box>
              <div className="select">
                <select
                  name="format"
                  id="format"
                  value={language}
                  onChange={handleLanguageChange}
                >
                  <option>English</option>
                  <option>Arabic</option>
                </select>
              </div>

            </Box>
            <Box
              sx={{
                // flexGrow: 1,
                display: { xs: "flex", md: "flex" },
                justifyContent: "right",
              }}
            >
              <div
                id="signInDiv"
                style={{ position: "relative", top: "1px", left: "10px" }}
              ></div>
              {credentialJWT && (
                <div
                  style={{ width: "40px", height: "40px", borderRadius: "50%" }}
                >
                  <React.Fragment>
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        textAlign: "center",
                      }}
                    >
                      <IconButton
                        onClick={handleClick}
                        size="small"
                        sx={{ ml: 2, position: "relative", top: "8px" }}
                        aria-controls={open ? "account-menu" : undefined}
                        aria-haspopup="true"
                        aria-expanded={open ? "true" : undefined}
                      >
                        {user && user.picture &&

                          <img
                            src={user && user.picture}
                            alt=""
                            style={{
                              width: "40px",
                              height: "40px",
                              borderRadius: "50%",
                            }}
                          />

                        }

                      </IconButton>
                    </Box>
                    <Menu
                      anchorEl={anchorEl}
                      id="account-menu"
                      open={open}
                      onClose={handleClose}
                      onClick={handleClose}
                      PaperProps={{
                        elevation: 0,
                        sx: {
                          overflow: "visible",
                          filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                          mt: 1.5,
                          "& .MuiAvatar-root": {
                            width: 32,
                            height: 32,
                            ml: -0.5,
                            mr: 1,
                          },
                          "&:before": {
                            content: '""',
                            display: "block",
                            position: "absolute",
                            top: 0,
                            right: 14,
                            width: 10,
                            height: 10,
                            bgcolor: "background.paper",
                            transform: "translateY(-50%) rotate(45deg)",
                            zIndex: 0,
                          },
                        },
                      }}
                      transformOrigin={{ horizontal: "right", vertical: "top" }}
                      anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
                    >
                      <MenuItem onClick={handleWishlist}>
                        Wishlist/Favourite
                      </MenuItem>
                      <MenuItem onClick={handleUpcomingInterviews}>
                        Upcoming Interviews
                      </MenuItem>
                      <MenuItem onClick={handleSignOut}>
                        <ListItemIcon>
                          <Logout fontSize="small" />
                        </ListItemIcon>
                        <Typography>Sign Out</Typography>
                      </MenuItem>
                    </Menu>
                  </React.Fragment>
                </div>
              )}
            </Box>
          </Toolbar>
        </Container>
      </AppBar>
    </Box>
  );
}

export default MainNav;
