import React from "react";
import { Box, Grid, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import serviceIcon from "../../assets/servicesIcon.png";

const useStyle = makeStyles({
  smallDivider: {
    width: "10%",
    height: "2px",
    backgroundColor: "#4ba0e8",
    margin: "auto",
  },
});
export default function OurServices(props) {
  const classes = useStyle();
  return (
    <Box className={classes.ourServices} sx={{ paddingBottom: "30px" }}>
      <Box
        sx={{
          width: { xs: "90%", sm: "90%", md: "80%", lg: "60%", xl: "60%" },
          margin: "auto",
          paddingTop: "40px",
        }}
      >
        <Typography
          sx={{
            fontFamily: "Poppins",
            fontSize: {
              xs: "24px",
              sm: "24px",
              md: "24px",
              lg: "36px",
              xl: "36px",
            },
            fontWeight: "600",
            color: "#4b5b68",
            textAlign: "center",
            paddingBottom: "10px",
          }}
        >
          {props.ourServicesContent.ourServicesHeading}
        </Typography>
        <Box className={classes.smallDivider}></Box>
        <Typography
          sx={{
            color: "#4b5b68",
            textAlign: "center",
            paddingBottom: "50px",
            paddingTop: "10px",
          }}
        >
          {props.ourServicesContent.ourServicesSubHeading}
        </Typography>
      </Box>
      <Box sx={{ backgroundColor: "#f9f9fa", padding: "20px 20px 20px 20px" }}>
        <Box sx={{ width: "85%", margin: "auto" }}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
              <Box
                sx={{
                  backgroundColor: "white",
                  padding: "10px 20px 10px 20px",
                  height: { xs: "auto", sm: "auto", md: "190px", lg: "190px", xl: "190px" },
                }}
              >
                <Grid container spacing={2}>
                  <Grid item xs={3} sm={2} md={2} lg={2} xl={2}>
                    <Box sx={{ textAlign: "right" }}>
                      <img
                        src={`${serviceIcon}`}
                        alt=""
                        style={{ width: "60px", height: "60px" }}
                      />
                    </Box>
                  </Grid>
                  <Grid item xs={9} sm={10} md={10} lg={10} xl={10}>
                    <Typography
                      sx={{
                        fontSize: "18px",
                        fontWeight: "600",
                        color: "#4b5b68",
                        paddingTop: "10px",
                      }}
                    >
                      {props.ourServicesContent.servicesOneHeading}
                    </Typography>
                    <Box
                      sx={{
                        fontSize: "16px",
                        fontWeight: "400",
                        color: "#4b5b68",
                      }}
                    >
                      <ul className="servicesItems" >
                        <li style={{ marginRight: "30px" }}>
                          {" "}
                          {props.ourServicesContent.serviceOneItemOne}
                        </li>
                        <li style={{ marginRight: "30px" }}>
                          {" "}
                          {props.ourServicesContent.serviceOneItemTwo}
                        </li>
                        <li style={{ marginRight: "30px" }}>
                          {" "}
                          {props.ourServicesContent.serviceOneItemThree}
                        </li>
                      </ul>
                      <ul className="servicesItems">
                        <li style={{ marginRight: "30px" }}>
                          {" "}
                          {props.ourServicesContent.serviceOneItemFour}
                        </li>
                        <li style={{ marginRight: "30px" }}>
                          {" "}
                          {props.ourServicesContent.serviceOneItemFive}
                        </li>


                      </ul>
                      <ul className="servicesItems">

                        <li style={{ marginRight: "30px" }}>
                          {" "}
                          {props.ourServicesContent.serviceOneItemSix}
                        </li>
                      </ul>
                    </Box>
                  </Grid>

                </Grid>
              </Box>
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
              <Box
                sx={{
                  backgroundColor: "white",
                  padding: "10px 20px 10px 20px",
                }}
              >
                <Grid container spacing={2}>
                  <Grid item xs={3} sm={2} md={2} lg={2} xl={2}>
                    <Box sx={{ textAlign: "right" }}>
                      <img
                        src={`${serviceIcon}`}
                        alt=""
                        style={{ width: "60px", height: "60px" }}
                      />
                    </Box>
                  </Grid>
                  <Grid item xs={9} sm={10} md={10} lg={10} xl={10}>
                    <Typography
                      sx={{
                        fontSize: "18px",
                        fontWeight: "600",
                        color: "#4b5b68",
                        paddingTop: "10px",
                      }}
                    >
                      {props.ourServicesContent.servicesTwoHeading}
                    </Typography>
                    <Box
                      sx={{
                        fontSize: "16px",
                        fontWeight: "400",
                        color: "#4b5b68",
                      }}
                    >
                      <ul className="servicesItems">
                        <li style={{ marginRight: "30px" }}>
                          {" "}
                          {props.ourServicesContent.serviceTwoItemOne}
                        </li>
                        <li style={{ marginRight: "30px" }}>
                          {" "}
                          {props.ourServicesContent.serviceTwoItemTwo}
                        </li>
                      </ul>
                      <ul className="servicesItems">
                        <li style={{ marginRight: "30px" }}>
                          {" "}
                          {props.ourServicesContent.serviceTwoItemThree}
                        </li>
                        <li style={{ marginRight: "30px" }}>
                          {" "}
                          {props.ourServicesContent.serviceTwoItemFour}
                        </li>
                      </ul>
                      <ul className="servicesItems">
                        <li style={{ marginRight: "30px" }}>
                          {" "}
                          {props.ourServicesContent.serviceTwoItemFive}
                        </li>
                        <li style={{ marginRight: "30px" }}>
                          {" "}
                          {props.ourServicesContent.serviceTwoItemSix}
                        </li>
                      </ul>
                      <ul className="servicesItems">
                        <li style={{ marginRight: "30px" }}>
                          {" "}
                          {props.ourServicesContent.serviceTwoItemSeven}
                        </li>
                        <li style={{ marginRight: "30px" }}>
                          {" "}
                          {props.ourServicesContent.serviceTwoItemEight}
                        </li>
                        <li style={{ marginRight: "30px" }}>
                          {" "}
                          {props.ourServicesContent.serviceTwoItemNine}
                        </li>
                      </ul>
                    </Box>
                  </Grid>

                </Grid>
              </Box>
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
              <Box
                sx={{
                  backgroundColor: "white",
                  padding: "10px 20px 10px 20px",
                }}
              >
                <Grid container spacing={2}>
                  <Grid item xs={3} sm={2} md={2} lg={2} xl={2}>
                    <Box sx={{ textAlign: "right" }}>
                      <img
                        src={`${serviceIcon}`}
                        alt=""
                        style={{ width: "60px", height: "60px" }}
                      />
                    </Box>
                  </Grid>
                  <Grid item xs={9} sm={10} md={10} lg={10} xl={10}>
                    <Typography
                      sx={{
                        fontSize: "18px",
                        fontWeight: "600",
                        color: "#4b5b68",
                        paddingTop: "10px",
                      }}
                    >
                      {props.ourServicesContent.servicesFourHeading}
                    </Typography>
                    <Box
                      sx={{
                        fontSize: "16px",
                        fontWeight: "400",
                        color: "#4b5b68",
                      }}
                    >
                      <ul className="servicesItems">
                        <li style={{ marginRight: "30px" }}>
                          {" "}
                          {props.ourServicesContent.serviceThreeItemOne}
                        </li>
                        <li style={{ marginRight: "30px" }}>
                          {" "}
                          {props.ourServicesContent.serviceThreeItemTwo}
                        </li>
                      </ul>
                      <ul className="servicesItems">
                        <li style={{ marginRight: "30px" }}>
                          {" "}
                          {props.ourServicesContent.serviceThreeItemThree}
                        </li>
                        <li style={{ marginRight: "30px" }}>
                          {" "}
                          {props.ourServicesContent.serviceThreeItemFour}
                        </li>
                      </ul>
                      <ul className="servicesItems">
                        <li style={{ marginRight: "30px" }}>
                          {" "}
                          {props.ourServicesContent.serviceThreeItemFive}
                        </li>
                      </ul>
                    </Box>
                  </Grid>


                </Grid>
              </Box>
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
              <Box
                sx={{
                  backgroundColor: "white",
                  padding: "10px 20px 10px 20px",
                }}
              >
                <Grid container spacing={2}>
                  <Grid item xs={3} sm={2} md={2} lg={2} xl={2}>
                    <Box sx={{ textAlign: "right" }}>
                      <img
                        src={`${serviceIcon}`}
                        alt=""
                        style={{ width: "60px", height: "60px" }}
                      />
                    </Box>
                  </Grid>
                  <Grid item xs={9} sm={10} md={10} lg={10} xl={10}>
                    <Typography
                      sx={{
                        fontSize: "18px",
                        fontWeight: "600",
                        color: "#4b5b68",
                        paddingTop: "10px",
                      }}
                    >
                      {props.ourServicesContent.servicesOneHeading}
                    </Typography>
                    <Box
                      sx={{
                        fontSize: "16px",
                        fontWeight: "400",
                        color: "#4b5b68",
                      }}
                    >
                      <ul>
                        <li style={{ marginRight: "30px" }}>
                          {" "}
                          {props.ourServicesContent.serviceFourItemOne}
                        </li>
                      </ul>
                      <ul>
                        <li style={{ marginRight: "30px" }}>
                          {" "}
                          {props.ourServicesContent.serviceFourItemTwo}
                        </li>
                      </ul>
                      <ul>
                        <li style={{ marginRight: "30px" }}>
                          {" "}
                          {props.ourServicesContent.serviceFourItemTwo}
                        </li>
                      </ul>
                    </Box>
                  </Grid>

                </Grid>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </Box>
  );
}
