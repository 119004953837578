import React, { useState } from "react";
import { Box, Typography, Rating } from "@mui/material";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import maid1 from "../assets/maid1.jpeg";
import quote1 from "../assets/quote1.png";
import quote2 from "../assets/quote2.png";

const responsive = {
  superLargeDesktop: {
    breakpoint: { max: 4000, min: 3000 },
    items: 1,
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 1,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 1,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
  },
};

export default function Testimonials(props) {
  const [value, setValue] = useState(2);
  return (
    <>
      <Box sx={{ marginTop: "40px", textAlign: "center" }}>
        <Typography
          sx={{
            fontFamily: "Poppins",
            fontSize: { xs: "24px", sm: "24px", md: "24px", lg: "36px", xl: "36px" },
            fontWeight: "600",
            color: "#4ba0e8",
          }}
        >
          {props.testimonialContent.happyClients}
        </Typography>
      </Box>
      <Carousel responsive={responsive} showDots={false}>
        <div style={{ width: "100%", margin: "auto", paddingBottom: "40px" }}>
          <Box>
            <Box sx={{ width: "70%", margin: "auto" }}>
              <Box>
                <img src={`${quote1}`} alt="" style={{ height: "30px", width: "50px" }} />
              </Box>
              <Typography
                sx={{
                  fontSize: { xs: "14px", sm: "14px", md: "14px", lg: "18px", xl: "18px" },
                  fontWeight: "500",
                  fontFamily: "Poppins",
                  textAlign: "center",
                  paddingTop: "20px",
                }}
              >
                They are very professional and do a great job cleaning the
                house!! I locked myself out of my house the other day and they
                were the only ones with a key. They were kind enough to drive
                over to unlock the door. That speaks volumes!!! They really care
                about their clients.
              </Typography>
              <Box sx={{ textAlign: "right" }}>
                <img src={`${quote2}`} alt="" style={{ height: "30px", width: "50px" }} />
              </Box>
              <Box sx={{ textAlign: "center", marginTop: "20px" }}>
                <img
                  src={`${maid1}`}
                  alt=""
                  style={{
                    height: "60px",
                    width: "60px",
                    borderRadius: "50%",
                    border: "4px solid #4ba0e8",
                  }}
                />
              </Box>
              <Typography
                style={{
                  fontSize: "24px",
                  fontWeight: "400",
                  fontFamily: "Poppins",
                  textAlign: "center",
                  paddingTop: "20px",
                  color: "#4ba0e8",
                }}
              >
                — Jesse Hudson.{" "}
                <span style={{ color: "grey" }}>Dothan, Alabama</span>
              </Typography>

              <Box sx={{ textAlign: "center" }}>
                <Rating
                  name="simple-controlled"
                  value={value}
                  readOnly
                  onChange={(event, newValue) => {
                    setValue(newValue);
                  }}
                />
              </Box>
            </Box>
          </Box>
        </div>
        <div style={{ width: "100%", margin: "auto", paddingBottom: "40px" }}>
          <Box>
            <Box sx={{ width: "70%", margin: "auto" }}>
              <Box>
                <img src={`${quote1}`} alt="" style={{ height: "30px", width: "50px" }} />
              </Box>
              <Typography
                sx={{
                  fontSize: { xs: "14px", sm: "14px", md: "14px", lg: "18px", xl: "18px" },
                  fontWeight: "500",
                  fontFamily: "Poppins",
                  textAlign: "center",
                  paddingTop: "20px",
                }}
              >
                They are very professional and do a great job cleaning the
                house!! I locked myself out of my house the other day and they
                were the only ones with a key.
              </Typography>
              <Box sx={{ textAlign: "right" }}>
                <img src={`${quote2}`} alt="" style={{ height: "30px", width: "50px" }} />
              </Box>
              <Box sx={{ textAlign: "center", marginTop: "20px" }}>
                <img
                  src={`${maid1}`}
                  alt=""
                  style={{
                    height: "60px",
                    width: "60px",
                    borderRadius: "50%",
                    border: "4px solid #4ba0e8",
                  }}
                />
              </Box>
              <Typography
                style={{
                  fontSize: "24px",
                  fontWeight: "400",
                  fontFamily: "Poppins",
                  textAlign: "center",
                  paddingTop: "20px",
                  color: "#4ba0e8",
                }}
              >
                — Jesse Hudson.{" "}
                <span style={{ color: "grey" }}>Dothan, Alabama</span>
              </Typography>

              <Box sx={{ textAlign: "center" }}>
                <Rating
                  name="simple-controlled"
                  value={value}
                  readOnly
                  onChange={(event, newValue) => {
                    setValue(newValue);
                  }}
                />
              </Box>
            </Box>
          </Box>
        </div>
        <div style={{ width: "100%", margin: "auto", paddingBottom: "40px" }}>
          <Box>
            <Box sx={{ width: "70%", margin: "auto" }}>
              <Box>
                <img src={`${quote1}`} alt="" style={{ height: "30px", width: "50px" }} />
              </Box>
              <Typography
                sx={{
                  fontSize: { xs: "14px", sm: "14px", md: "14px", lg: "18px", xl: "18px" },
                  fontWeight: "500",
                  fontFamily: "Poppins",
                  textAlign: "center",
                  paddingTop: "20px",
                }}
              >
                They are very professional and do a great job cleaning the
                house!! I locked myself out of my house the other day and they
                were the only ones with a key. They were kind enough to drive
                over to unlock the door. That speaks volumes!!! They really care
                about their clients.
              </Typography>
              <Box sx={{ textAlign: "right" }}>
                <img src={`${quote2}`} alt="" style={{ height: "30px", width: "50px" }} />
              </Box>
              <Box sx={{ textAlign: "center", marginTop: "20px" }}>
                <img
                  src={`${maid1}`}
                  alt=""
                  style={{
                    height: "60px",
                    width: "60px",
                    borderRadius: "50%",
                    border: "4px solid #4ba0e8",
                  }}
                />
              </Box>
              <Typography
                style={{
                  fontSize: "24px",
                  fontWeight: "400",
                  fontFamily: "Poppins",
                  textAlign: "center",
                  paddingTop: "20px",
                  color: "#4ba0e8",
                }}
              >
                — Jesse Hudson.{" "}
                <span style={{ color: "grey" }}>Dothan, Alabama</span>
              </Typography>

              <Box sx={{ textAlign: "center" }}>
                <Rating
                  name="simple-controlled"
                  value={value}
                  readOnly
                  onChange={(event, newValue) => {
                    setValue(newValue);
                  }}
                />
              </Box>
            </Box>
          </Box>
        </div>
      </Carousel>
    </>
  );
}
